import React, { Component } from 'react';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import CONSTANTS from '../constants';
import { loadStripe } from '@stripe/stripe-js';

import StripeComponent from '../components/StripeComponent';
import UpdateCard from '../components/UpdateCard';
//  import SimpleStripeLoader from '../components/SimpleStripeLoader';

import Axios from '../api/axios';

const stripePromise = loadStripe(CONSTANTS.STRIPE_PKEY);

export default class Billing extends Component {

  state = {
    selectedPlan: 'free',
    cardElement: null,
    cardElement2: null,
    stripe: null,
    showUpdatePaymentMethodsModal: false,
    showUpdatePlanModal: false,
    showErrorModal: false,
    showSuccessModal: false,
    showInfoModal: false,
    authId: this.props.id,
    status: "",
    plan: "free",
    agentsCount: 1,
    profilesCount: 1,
    selectedAgentsCount: 1,
    selectedProfilesCount: 1,
    invoices: [],
    nextPaymentAttempt: '-',
    nameOnCard: "",
    paymentMethods: [],
    defaultPaymentMethod: "",
    selectedPaymentMethod: "",
    errorMessage: "",
    successMessage: "",
    infoMessage: "",
    updating: false,
    nextPaymentAmount: 0,
    ready: false,
    agent: true,
    error: false,
  }

  setCardElement = (cardElement, stripe) => {
    //console.log({ cardElement, stripe });
    this.setState({ cardElement, stripe });
  }

  setCardElement2 = (cardElement2, stripe) => {
    //console.log({ cardElement2, stripe });
    this.setState({ cardElement2, stripe });
  }

  componentDidMount() {

    this.setState({ updating: true });

    Axios({
      method: 'get',
      url: '/auth/subscription',
      headers: { 'x-auth': this.props.id },
    })
      .then(({ data }) => {

        this.setState({
          status: data.status,
          plan: data.plan,
          agentsCount: data.agentsCount,
          profilesCount: data.profilesCount,
          invoices: data.invoices,
          nextPaymentAttempt: data.nextPaymentAttempt === "-" ? "-" : new Date(parseInt(data.nextPaymentAttempt, 10) * 1000).toLocaleDateString(),
          selectedPlan: data.plan,
          paymentMethods: data.paymentMethods,
          defaultPaymentMethod: data.defaultPaymentMethod,
          selectedPaymentMethod: data.paymentMethods[0] ? data.paymentMethods[0].id : "",
          updating: false,
          nextPaymentAmount: data.nextPaymentAmount,
          ready: true,
          agent: data.agent,
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false, error: true });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });


    const searchParams = new URLSearchParams(window.location.search);
    let update = searchParams.get('update');
    if (update) {
      this.setState({ showUpdatePlanModal: true });
    }
  }

  getTotalPerMonth = () => {
    const {
      plan, agentsCount, profilesCount,
    } = this.state;
    if (plan === "free") return 0;

    if (plan === "pro") return Math.round((14.99 + Math.round(9.99 * (profilesCount - 1) * 100) / 100) * 100) / 100;

    return Math.round((29.99 + Math.round(9.99 * (profilesCount - 1) * 100) / 100 + Math.round(9.99 * (agentsCount - 1) * 100) / 100) * 100) / 100;
  }

  getToPay = () => {
    const {
      selectedAgentsCount, selectedPlan, selectedProfilesCount
    } = this.state;
    if (selectedPlan === "free") return 0;

    if (selectedPlan === "pro") return Math.round((14.99 + Math.round(9.99 * (selectedProfilesCount - 1) * 100) / 100) * 100) / 100;

    return Math.round((29.99 + Math.round(9.99 * (selectedProfilesCount - 1) * 100) / 100 + Math.round(9.99 * (selectedAgentsCount - 1) * 100) / 100) * 100) / 100;
  }

  getCardIcon = (brand) => {
    switch (brand) {
      case 'mastercard': {
        return "fab fa-cc-mastercard";
      }
      case 'visa': {
        return "fab fa-cc-visa";
      }
      case 'diners': {
        return "fab fa-cc-diners-club";
      }
      case 'discover': {
        return "fab fa-cc-discover";
      }
      case 'jcb': {
        return "fab fa-cc-jcb";
      }
      case 'amex': {
        return "fab fa-cc-amex";
      }
      default: {
        return "fas fa-credit-card";
      }
    }
  }

  setDefaultCard = (paymentMethodId) => {
    this.setState({ updating: true });
    Axios({
      method: 'post',
      url: '/auth/setDefaultPaymentMethod',
      headers: { 'x-auth': this.props.id },
      data: {
        paymentMethodId,
      },
    })
      .then(({ data }) => {

        this.setState({
          paymentMethods: data.paymentMethods,
          defaultPaymentMethod: data.defaultPaymentMethod,
          selectedPaymentMethod: data.paymentMethods[0] ? data.paymentMethods[0].id : "",
          updating: false,
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  deleteCard = (paymentMethodId) => {
    this.setState({ updating: true });
    Axios({
      method: 'delete',
      url: `/auth/paymentMethod/${paymentMethodId}`,
      headers: { 'x-auth': this.props.id },
    })
      .then(({ data }) => {

        console.log({ data });

        this.setState({
          paymentMethods: data.paymentMethods,
          defaultPaymentMethod: data.defaultPaymentMethod,
          selectedPaymentMethod: data.paymentMethods[0] ? data.paymentMethods[0].id : "",
          updating: false,
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  showError = (msg, delay = 10) => {
    this.setState({
      showErrorModal: true,
      errorMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showErrorModal: false,
          errorMessage: "",
        })
      }, delay * 1000);
    })
  }

  showSuccess = (msg, delay = 10) => {
    this.setState({
      showSuccessModal: true,
      successMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showSuccessModal: false,
          successMessage: "",
        })
      }, delay * 1000);
    })
  }

  showInfo = (msg, delay = 10) => {
    this.setState({
      showInfoModal: true,
      infoMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showInfoModal: false,
          infoMessage: "",
        })
      }, delay * 1000);
    })
  }

  addCard = async () => {
    const { paymentMethods, stripe, cardElement, nameOnCard } = this.state;
    if (paymentMethods.length >= 3 || !nameOnCard) {
      return;
    }

    this.setState({ updating: true });

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: { name: nameOnCard },
    });

    const paymentMethod = result.paymentMethod;

    if (result.error) {
      this.setState({ updating: false });
      return this.showError(result.error.message);
    }

    Axios({
      method: 'post',
      url: '/auth/addPaymentMethod',
      headers: { 'x-auth': this.props.id },
      data: {
        paymentMethodId: paymentMethod.id,
      },
    })
      .then(({ data }) => {

        console.log({ data });

        this.setState({
          paymentMethods: data.paymentMethods,
          defaultPaymentMethod: data.defaultPaymentMethod,
          selectedPaymentMethod: data.paymentMethods[0] ? data.paymentMethods[0].id : "",
          updating: false,
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });


  }

  updatePlan = async () => {

    const { stripe, cardElement2, nameOnCard, selectedPlan, selectedAgentsCount, selectedProfilesCount, selectedPaymentMethod } = this.state;

    if (!selectedPaymentMethod && !nameOnCard && selectedPlan !== "free") {
      return;
    }

    if (!["free", "pro", "business"].includes(selectedPlan)) return;

    if ((selectedAgentsCount < 1 || selectedAgentsCount > 20) && selectedPlan === "business") {
      this.showError("Please enter a valid number for the agents seats");
    }

    if (selectedProfilesCount < 1 || selectedProfilesCount > 20) {
      this.showError("Please enter a valid number for the profiles count");
    }

    this.setState({ updating: true });

    let result = "";
    let newCard = false;

    let paymentMethod = selectedPaymentMethod ? selectedPaymentMethod : "";

    if (!selectedPaymentMethod && selectedPlan !== "free") {
      newCard = true;
      result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement2,
        billing_details: { name: nameOnCard },
      });

      if (result.error) {
        this.setState({ updating: false });
        return this.showError(result.error.message);
      }

      paymentMethod = result.paymentMethod.id;
    }

    Axios({
      url: `/auth/updateSubscription`,
      method: 'post',
      headers: { 'x-auth': this.props.id },
      data: {
        type: selectedPlan,
        agentsCount: selectedAgentsCount,
        profilesCount: selectedProfilesCount,
        paymentMethodId: paymentMethod,
        newCard,
      }
    })
      .then((res) => {
        // console.log(res);

        this.setState({ updating: false });

        const { paymentIntent, authToken } = res.data;

        if (!paymentIntent) {
          this.showSuccess('Successfully Updated!');

          setTimeout(() => {
            window.location.href = `${CONSTANTS.WEBAPP_URL}/billing?id=${authToken}`;
          }, 2000);
          return;
        }

        if (paymentIntent.status === "succeeded") {

          this.showSuccess('Successfully Updated!');

          setTimeout(() => {
            window.location.href = `${CONSTANTS.WEBAPP_URL}/billing?id=${authToken}`;
          }, 2000);
        } else if (paymentIntent.status === "requires_action" || paymentIntent.status === "requires_payment_method") {
          this.showInfo('Authentication required!', 3);

          this.setState({ updating: true });

          stripe
            .confirmCardPayment(paymentIntent.client_secret, {
              payment_method: paymentMethod.id,
            })
            .then((result) => {
              if (!result.error) {

                // Make a request to backend to make sure it was ok for real
                Axios({
                  url: `/auth/completeSubscription`,
                  method: 'get',
                  headers: { 'x-auth': this.props.id },
                  data: {
                    type: selectedPlan,
                    profilesCount: selectedProfilesCount,
                    agentsCount: selectedAgentsCount,
                    nameOnCard,
                    paymentMethodId: paymentMethod,
                  }
                })
                  .then((res) => {
                    // console.log(res);

                    this.showSuccess('Complete', 2);

                    setTimeout(() => {
                      window.location.href = `${CONSTANTS.WEBAPP_URL}/billing?id=${res.data.authToken}`;
                    }, 2000);
                  })
                  .catch(({ response }) => {

                    this.setState({ updating: false });

                    if (response) {
                      const { status } = response;

                      if (status === 401) {
                        window.localStorage.removeItem('id');
                        window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
                      } else if (status === 429) {
                        this.showError("Please slow down! Wait 2 minutes and try again");
                      } else if (status === 400) {
                        this.showError(response.data.errors[0].msg);
                      } else if (status === 500) {
                        this.showError(response.data.message);
                      } else {
                        this.showError("An unexpected error occured!");
                      }
                    } else {
                      this.showError("Network error!");
                    }

                  });

              } else {

                this.setState({ updating: false });
                this.showError('Card authentication failed!', 5);
                setTimeout(() => {
                  window.location.href = `${CONSTANTS.WEBAPP_URL}/billing?id=${res.data.authToken}`;
                }, 2000);
              }
            })
            .catch((e) => {

              this.setState({ updating: false });
              // console.log(e);
              this.showError('Card authentication failed!', 5);
              setTimeout(() => {
                window.location.href = `${CONSTANTS.WEBAPP_URL}/billing?id=${authToken}`;
              }, 2000);
            });

        } else {
          this.showError('An unexpected error occured!', 5);
        }

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  render() {
    const {
      showUpdatePaymentMethodsModal, showUpdatePlanModal, status, plan, agentsCount, profilesCount, nextPaymentAttempt, invoices,
      selectedPlan, selectedAgentsCount, selectedProfilesCount, paymentMethods, defaultPaymentMethod, selectedPaymentMethod, nameOnCard,
      errorMessage, showErrorModal, updating, showSuccessModal, successMessage, infoMessage, showInfoModal, nextPaymentAmount, ready,
      agent, error,
    } = this.state;

    return (
      <div className="">

        {(ready || error) || <div className="mx-auto max-w-billing">
          <h1 className="text-2xl font-nunito font-bold">Loading...</h1>
        </div>}

        {
          error && !ready && <p className='p-4 bg-white rounded-md mt-2 font-fira font-medium text-gray-7 mx-auto max-w-billing'>An unexpected error occured, reload the page!</p>
        }

        {ready &&

          <React.Fragment>

            <div className="mx-auto max-w-billing">
              <h1 className="text-2xl font-nunito font-bold">Billing</h1>
              <p className="text-base font-fira text-gray-400">Manage your subscription</p>

              {
                agent && <p className='p-4 bg-white rounded-md mt-2 font-fira font-medium text-gray-7'>You don't have access to this page.</p>
              }

              {
                !agent && <React.Fragment>

                  <div className="flex space-x-9">
                    <div className="bg-white flex justify-between mt-8 rounded-md shadow-sm max-w-sm w-full">
                      <div className="p-6 pr-3">
                        <p className="font-semibold font-fira text-sm">
                          <span className="py-1 px-2 text-sm font-source bg-indigo-900 text-white rounded-md">
                            {plan.toUpperCase()}
                          </span>&nbsp;
                          <span className="text-yellow-600">({status ? 'Active' : 'Inactive'})</span>
                        </p>
                        <div className="mt-9 font-fira font-semibold text-gray-600">
                          <p>{profilesCount} Profile(s)</p>
                          {plan === 'business' && <p>{agentsCount} Agent(s)</p>}
                        </div>
                      </div>
                      <div className="p-6 pl-3 pt-4">
                        <p className="font-fira align-top">
                          $ <span className="text-5xl font-bold">{this.getTotalPerMonth()}</span> <span>/month</span>
                        </p>
                        <p className="mt-6">
                          <button
                            disabled={updating}
                            onClick={() => {
                              this.setState({ showUpdatePlanModal: true });
                            }}
                            className="bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200">Update Plan</button>
                        </p>
                      </div>
                    </div>
                    <div className="bg-white mt-8 rounded-md shadow-sm w-full max-w-sm p-6 pt-5">
                      <p className="text-gray-500 font-semibold">Next payment</p>
                      <p className="text-gray-800 font-bold"><span className="text-lg font-fira">${typeof (nextPaymentAmount) === 'number' ? ((nextPaymentAmount) / 100) : '0'}</span> on {nextPaymentAttempt}</p>
                      <p className="mt-5">
                        <button
                          disabled={updating}
                          onClick={() => {
                            this.setState({ showUpdatePaymentMethodsModal: true });
                          }}
                          className="text-indigo-500 border-2 border-indigo-500 rounded-md p-3 font-source font-bold inline-block hover:bg-indigo-100 duration-200">
                          Manage Payment Methods
                        </button>
                      </p>
                    </div>
                  </div>

                  <h2 className="text-lg font-fira font-semibold text-gray-700 mt-10">Invoices <span className="text-gray-400">({invoices.length})</span> </h2>

                  {
                    invoices.length === 0 && <p className="text-gray-600 font-fira mt-4">No paid invoice found for this account</p>
                  }
                  <ul className="mt-4 invoices max-w-screen-md">
                    {
                      invoices.map((inv, i) => (
                        <li className="p-3 flex bg-white space-x-10 rounded-md shadow-sm mt-2" key={i}>
                          <p className="text-xl">
                            <i className="far fa-file-pdf text-2xl p-2 px-3 border rounded-md align-middle text-indigo-600"></i> &nbsp;&nbsp;
                            <span className="align-middle"><span className="font-fira font-semibold text-gray-600">${(inv.amount_paid) / 100}</span> on <span className="font-semibold text-gray-600">{new Date(inv.created * 1000).toLocaleDateString()}</span></span>
                          </p>
                          <p className="cursor-pointer" title={`Invoice ${inv.number}`} style={{ alignSelf: "center", marginLeft: "auto" }} >
                            <a href={inv.invoice_pdf} target="_blank" rel="noreferrer">
                              <span className="material-icons-outlined align-middle p-2 px-3 text-3xl text-indigo-400 opacity-0 download">file_download</span>
                            </a>
                          </p>
                        </li>
                      ))
                    }
                  </ul>

                </React.Fragment>
              }

            </div>

            {/* PAYMENT METHOD MODAL */}
            <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showUpdatePaymentMethodsModal ? '' : 'hidden'}`}
              onClick={() => {
                this.setState({ showUpdatePaymentMethodsModal: false, nameOnCard: "" });
              }}
            >
              <div className="modal bg-white max-w-lg rounded-lg shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
                <p className="bg-white flex justify-between p-6 rounded-sm border-b">
                  <span className="text-lg font-fira font-bold text-gray-500">Payment methods</span>
                  <span
                    onClick={() => {
                      this.setState({ showUpdatePaymentMethodsModal: false, nameOnCard: "" });
                    }}
                    className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                    close
                  </span>
                </p>
                {/* LIST OF PAYMENT METHODS MAX 3 */}
                <div className="p-4 py-2">
                  <p className="text-xl font-fira font-medium">Your cards <span className="text-gray-500 text-xs">(3 max)</span></p>
                  {
                    paymentMethods.length === 0 && <p className="text-gray-600 font-fira">You have not attached any payment method</p>
                  }
                  {
                    paymentMethods.map((pm, i) => (
                      <div key={i} className={`card flex bg-white p-2 shadow-md space-x-5 mt-2 cursor-pointer rounded border-4 border-opacity-0 ${pm.id === defaultPaymentMethod ? 'border-opacity-100 border-indigo-500' : ''}`}>
                        <p>
                          <i className={`${this.getCardIcon(pm.card.brand)} text-5xl text-indigo-400`}></i>
                        </p>
                        <div>
                          <p className="text-lg font-fira">**** **** **** {pm.card.last4}</p>
                          <p className="font-fira text-gray-400 text-sm">Expires {pm.card.exp_month}/{pm.card.exp_year}</p>
                        </div>
                        <p className="pl-6 text-right font-fira" style={{ alignSelf: "center" }}>
                          {pm.id === defaultPaymentMethod ? 'DEFAULT' : <button disabled={updating} onClick={(e) => {
                            e.stopPropagation();
                            this.setDefaultCard(pm.id);
                          }} className="text-indigo-600 font-semibold hover:bg-indigo-100">SET DEFAULT</button>}
                        </p>
                        <p className="card-delete hidden pr-8 text-right" style={{ alignSelf: "center", marginLeft: "auto" }}>
                          <button disabled={updating} onClick={(e) => {
                            e.stopPropagation();
                            this.deleteCard(pm.id);
                          }} className="material-icons-outlined hover:bg-indigo-100">delete</button>
                        </p>
                      </div>
                    ))
                  }
                </div>
                {/* ADD PAYEMNT METHOD */}
                {(paymentMethods.length === 3) || <div className="p-4 py-2 mt-2">
                  <div className="">
                    <p className="text-gray-700 font-fira text-lg font-semibold">Name on Card</p>
                    <input value={nameOnCard} onChange={(e) => this.setState({ nameOnCard: e.target.value })} autoComplete="name" required placeholder="John doe" type="text" name="password" id="password" className="block w-full h-12 px-6  border border-indigo-400 rounded-md mt-1 outline-none font-fira font-medium" />
                  </div>
                  <div className="mt-2">
                    <Elements stripe={stripePromise}>
                      <ElementsConsumer>
                        {(ctx) => <UpdateCard setCardElement={this.setCardElement} {...ctx} />}
                      </ElementsConsumer>
                    </Elements>
                  </div>
                  <div className="mt-4">
                    <button
                      disabled={updating}
                      onClick={this.addCard}
                      className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                      <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${updating ? '' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Add card
                    </button>
                  </div>
                </div>
                }
              </div>
            </div>

            {/* UPDATE PLAN MODAL */}
            <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showUpdatePlanModal ? '' : 'hidden'}`}
              onClick={() => {
                this.setState({ showUpdatePlanModal: false, selectedPlan: plan, selectedProfilesCount: 1, selectedAgentsCount: 1 });
              }}
            >
              <div className="modal bg-white max-w-lg rounded-lg shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
                <p className="bg-white flex justify-between p-6 rounded-sm border-b">
                  <span className="text-lg font-fira font-bold text-gray-500">Update your plan</span>
                  <span
                    onClick={() => {
                      this.setState({ showUpdatePlanModal: false, selectedPlan: plan, selectedProfilesCount: 1, selectedAgentsCount: 1 });
                    }}
                    className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                    close
                  </span>
                </p>
                {/* LIST OF PLANS */}
                <div className="p-4 py-2">
                  <p className="text-xl font-fira font-medium">Choose a plan &nbsp;
                    <a href="https://katario.com/pricing/sms" target="_blank" rel="noreferrer">
                      <span className="text-xs text-indigo-500">See pricing plans</span>
                    </a>
                  </p>
                  <div className="flex flex-row space-x-2">
                    <div
                      onClick={() => {
                        this.setState({ selectedPlan: "free" });
                      }}
                      className={`flex bg-white p-2 pl-2 shadow-md space-x-4 mt-1 cursor-pointer py-4 rounded border-2 border-opacity-0 ${selectedPlan === "free" ? "border-2 border-opacity-100 border-indigo-500" : ""}`}
                    >
                      <p className="" style={{ alignSelf: "center" }}>
                        <input type="radio" name="plan" readOnly checked={selectedPlan === "free"} className="text-2xl box-content w-5 h-5 bg-indigo-500 cursor-pointer" />
                      </p>
                      <div className="">
                        <p className="text-lg font-fira">Free</p>
                        <p className="font-fira text-gray-500 text-base">$0 /month</p>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        this.setState({ selectedPlan: "pro" });
                      }}
                      className={`flex bg-white p-2 pl-2 shadow-md space-x-4 mt-2 cursor-pointer py-4 rounded border-2 border-opacity-0  ${selectedPlan === "pro" ? "border-2 border-opacity-100 border-indigo-500" : ""}`}>
                      <p className="" style={{ alignSelf: "center" }}>
                        <input type="radio" name="plan" readOnly checked={selectedPlan === "pro"} className="text-2xl box-content w-5 h-5 bg-indigo-500 cursor-pointer" />
                      </p>
                      <div className="">
                        <p className="text-lg font-fira">Pro</p>
                        <p className="font-fira text-gray-500 text-base">$14.99 /month</p>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        this.setState({ selectedPlan: "business" });
                      }}
                      className={`flex bg-white p-2 pl-2 shadow-md space-x-4 mt-2 cursor-pointer py-4 rounded border-2 border-opacity-0 ${selectedPlan === "business" ? "border-2 border-opacity-100 border-indigo-500" : ""}`}>
                      <p className="" style={{ alignSelf: "center" }}>
                        <input type="radio" name="plan" readOnly checked={selectedPlan === "business"} className="text-2xl box-content w-5 h-5 bg-indigo-500 cursor-pointer" />
                      </p>
                      <div className="">
                        <p className="text-lg font-fira">Business</p>
                        <p className="font-fira text-gray-500 text-base">$29.99 /month</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* PROFILES COUNTS (NUMBER OF DIFFERENT PHONE NUMBER) */}
                <div className="mt-2 px-4 py-1 flex space-x-5">
                  <span className="text-lg font-fira" style={{ alignSelf: "center" }}>Profiles count (max 20) &nbsp;
                    <a href="https://docs.katario.com/help/whats-a-profile" target="_blank" rel="noreferrer" >
                      <span className="text-xs text-indigo-500">What's a profile?</span>
                    </a>
                  </span>
                  <div className="quantity buttons_added pr-12" style={{ alignSelf: "center", marginLeft: "auto" }}>
                    <input
                      onClick={() => {
                        this.setState({ selectedProfilesCount: selectedProfilesCount <= 1 ? 1 : selectedProfilesCount - 1 });
                      }}
                      type="button" value="-" readOnly className="minus font-fira font-semibold h-12" />
                    <input type="number" step="1" min="1" max="20" name="quantity" readOnly value={selectedPlan === "free" ? 1 : selectedProfilesCount} title="Qty" className="input-text qty text font-fira font-semibold" size="4" pattern="" inputMode="" />
                    <input
                      onClick={() => {
                        if (selectedPlan === "free") {
                          this.setState({ selectedProfilesCount: 1 });
                        } else {
                          this.setState({ selectedProfilesCount: selectedProfilesCount >= 20 ? 20 : selectedProfilesCount + 1 });
                        }
                      }}
                      type="button" value="+" readOnly className="plus font-fira font-semibold" />
                  </div>
                </div>

                {/* AGENTS SEATS */}
                {selectedPlan === "business" && <div className="px-4 py-1 flex space-x-5 mt-1">
                  <span className="text-lg font-fira" style={{ alignSelf: "center" }}>Agents seats (max 20) &nbsp;
                    <a href="https://docs.katario.com/help/whats-an-agent" target="_blank" rel="noreferrer">
                      <span className="text-xs text-indigo-500">What's an agent?</span>
                    </a>
                  </span>
                  <div className="quantity buttons_added pr-12" style={{ alignSelf: "center", marginLeft: "auto" }}>
                    <input
                      onClick={() => {
                        this.setState({ selectedAgentsCount: selectedAgentsCount <= 1 ? 1 : selectedAgentsCount - 1 });
                      }}
                      type="button" value="-" readOnly className="minus font-fira font-semibold" />
                    <input type="number" step="1" min="1" max="20" name="quantity" readOnly value={selectedAgentsCount} title="Qty" className="input-text qty text font-fira font-semibold" size="4" pattern="" inputMode="" />
                    <input
                      onClick={() => {
                        this.setState({ selectedAgentsCount: selectedAgentsCount >= 20 ? 20 : selectedAgentsCount + 1 });
                      }}
                      type="button" value="+" readOnly className="plus font-fira font-semibold" />
                  </div>
                </div>}

                {/* TOTAL TO PAY */}
                {/* <div className="mt-0 px-4 py-2 flex space-x-5">
    <p className="text-lg font-fira font-medium text-gray-600">You will pay <span className="text-xl text-gray-900">$0</span> every month</p>
  </div> */}

                {/* LIST OF PAYMENT METHODS MAX 3 */}
                {(selectedPlan !== "free") && <div className="p-4 py-2">
                  <p className="text-gray-600 font-fira font-semibold text-lg">Pay with</p>
                  {
                    paymentMethods.map((pm, i) => (
                      <div onClick={() => this.setState({ selectedPaymentMethod: pm.id })} key={i} className={`card flex bg-white p-2 shadow-md space-x-5 mt-2 cursor-pointer rounded border-4 border-opacity-0 ${pm.id === selectedPaymentMethod ? 'border-opacity-100 border-purple-500' : ''}`}>
                        <p>
                          <i className={`${this.getCardIcon(pm.card.brand)} text-5xl text-indigo-400`}></i>
                        </p>
                        <div>
                          <p className="text-lg font-fira">**** **** **** {pm.card.last4}</p>
                          <p className="font-fira text-gray-400 text-sm">Expires {pm.card.exp_month}/{pm.card.exp_year}</p>
                        </div>
                      </div>
                    ))
                  }
                </div>}

                {/* ADD CARD */}
                <div className="p-4 pt-0 pb-2">
                  {(paymentMethods.length === 0 && selectedPlan !== "free") && (<React.Fragment>
                    <div className="">
                      <p className="text-gray-600 font-fira text-lg">Name on Card</p>
                      <input value={nameOnCard} onChange={(e) => this.setState({ nameOnCard: e.target.value })} autoComplete="name" required placeholder="John doe" type="text" name="password" id="password" className="block w-full h-12 px-6  border border-indigo-400 rounded-md mt-1 outline-none font-fira font-medium" />
                    </div>
                    <div className="mt-2">
                      <Elements stripe={stripePromise}>
                        <ElementsConsumer>
                          {(ctx) => <StripeComponent setCardElement={this.setCardElement2} {...ctx} />}
                        </ElementsConsumer>
                      </Elements>
                    </div>
                  </React.Fragment>)}
                  <div className="mt-4">
                    <button disabled={updating} onClick={this.updatePlan} className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                      <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${updating ? '' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Update and Pay ${this.getToPay()} every month
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </React.Fragment>
        }

        {/* ERROR MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showErrorModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showErrorModal: false, errorMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-red-500">{errorMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showErrorModal: false, errorMessage: "" });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* SUCCESS MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showSuccessModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showSuccessModal: false, successMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-green-500">{successMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showSuccessModal: false, successMessage: "" });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* INFO MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showInfoModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showInfoModal: false, infoMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-blue-500">{infoMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showInfoModal: false, infoMessage: "" });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

      </div>
    )
  }
}

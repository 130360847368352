import React, { useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const StripeComponent = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const { setCardElement } = props;

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    setCardElement(cardElement, stripe);

  }, [stripe, setCardElement, elements]);

  return (
    <CardElement
      className="shadow-sm p-4 rounded-sm"
      options={{
        style: {
          base: {
            fontSize: '16px',
            color: '#424770',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
      }}
    />
  )
}

export default StripeComponent;

import React, { useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const UpdateCard = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const { setCardElement } = props;

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    setCardElement(cardElement, stripe);

  }, [stripe, setCardElement, elements]);

  return (
    <CardElement
      className="shadow-sm p-4 rounded-sm font-fira"
      options={{
        style: {
          base: {
            fontSize: '16px',
            color: '#4F46E5',
            fontWeight: '600',
            fontFamily: 'Fira Sans, sans-serif',
            '::placeholder': {
              color: '#4F46E5',
            },
            iconColor: '#000'
          },
          invalid: {
            color: '#9e2146',
          },
        },
      }}
    />
  )
}

export default UpdateCard;

import React, { Component } from 'react';
import Axios from '../api/axios';

import CONSTANTS from '../constants';

export default class Account extends Component {
  state = {
    ready: false,
    status: "",
    plan: "free",
    showErrorModal: false,
    showSuccessModal: false,
    showInfoModal: false,
    showConfirmModal: false,
    errorMessage: "",
    successMessage: "",
    infoMessage: "",
    confirmMessage: "",
    updating: false,
    email: "",
    fullname: "",
    soundNotificationOn: false,
    emailNotificationOn: false,
    smsContentInNotification: false,
    oldPassword: "",
    newPassword: "",
    registeredWithGoogle: false,
    confirmCallback: {},
    error: false,
  };

  componentDidMount() {
    this.setState({ updating: true });

    Axios({
      method: 'get',
      url: '/auth/me',
      headers: { 'x-auth': this.props.id },
    })
      .then(({ data }) => {

        this.setState({
          me: data.me,
          soundNotificationOn: data.soundNotificationOn,
          emailNotificationOn: data.emailNotificationOn,
          smsContentInNotification: data.smsContentInNotification,
          updating: false,
          ready: true,
          email: data.me.email,
          fullname: data.me.fullname,
          registeredWithGoogle: data.registeredWithGoogle,
          plan: data.plan,
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false, error: true });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  showError = (msg, delay = 10) => {
    this.setState({
      showErrorModal: true,
      errorMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showErrorModal: false,
          errorMessage: "",
        })
      }, delay * 1000);
    })
  }

  showSuccess = (msg, delay = 10) => {
    this.setState({
      showSuccessModal: true,
      successMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showSuccessModal: false,
          successMessage: "",
        })
      }, delay * 1000);
    })
  }

  showInfo = (msg, delay = 10) => {
    this.setState({
      showInfoModal: true,
      infoMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showInfoModal: false,
          infoMessage: "",
        })
      }, delay * 1000);
    })
  }

  showConfirm = (msg, callback) => {
    this.setState({
      showConfirmModal: true,
      confirmMessage: msg,
      confirmCallback: callback,
    })
  }

  updateAccountDetails = () => {
    const { fullname, oldPassword, newPassword, soundNotificationOn, emailNotificationOn, smsContentInNotification, registeredWithGoogle } = this.state;

    if (!oldPassword && !registeredWithGoogle) {
      return;
    }

    this.setState({ updating: true });

    Axios({
      method: 'post',
      url: '/auth/update',
      headers: { 'x-auth': this.props.id },
      data: {
        fullname,
        oldPassword,
        newPassword,
        soundNotificationOn,
        emailNotificationOn,
        smsContentInNotification,
      }
    })
      .then((_) => {

        this.setState({
          updating: false,
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });


  };

  deleteAccount = () => {
    this.setState({ updating: true });

    Axios({
      method: 'post',
      url: '/auth/deleteMe',
      headers: { 'x-auth': this.props.id },
    })
      .then((_) => {

        this.setState({ updating: false });

        window.localStorage.removeItem('id');
        window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  };

  render() {
    const { ready, errorMessage, showErrorModal, successMessage, showSuccessModal,
      infoMessage, showInfoModal, email, fullname, updating,
      oldPassword, newPassword, soundNotificationOn, emailNotificationOn, smsContentInNotification,
      plan, registeredWithGoogle, confirmMessage, showConfirmModal, confirmCallback, error
    } = this.state;
    return (
      <div className="">
        {(ready || error) || <div className="mx-auto max-w-billing">
          <h1 className="text-2xl font-nunito font-bold">Loading...</h1>
        </div>}

        {
          error && !ready && <p className='p-4 bg-white rounded-md mt-2 font-fira font-medium text-gray-7 mx-auto max-w-billing'>An unexpected error occured, reload the page!</p>
        }
        {
          ready && <div className="mx-auto max-w-billing">
            <h1 className="text-2xl font-nunito font-bold">Account</h1>
            <p className="text-base font-fira text-gray-400">Update your account details</p>

            <form>

              <div className="bg-white p-8 pt-2 mt-4 rounded-md">
                <div className="mt-5">
                  <label htmlFor="email" className="text-sm font-fira font-medium">EMAIL ADDRESS</label>
                  <input autoComplete="username" readOnly value={email} required placeholder="johndoe@example.com" type="email" name="email" id="email"
                    className="block w-full h-12 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira font-medium text-gray-600" />
                </div>

                <div className="mt-5">
                  <label htmlFor="fullname" className="text-sm font-fira font-medium">FULL NAME</label>
                  <input autoComplete="name" value={fullname} onChange={(e) => { this.setState({ fullname: e.target.value }) }} required placeholder="John Doe" type="text" name="fullname" id="fullname"
                    className="block w-full h-12 px-6 border border-indigo-400 rounded-md mt-2 text-lg font-fira font-medium text-gray-600" />
                </div>

                {registeredWithGoogle ||
                  <React.Fragment>
                    <div className="mt-5">
                      <label htmlFor="oldPassword" className="text-sm font-fira font-medium">CURRENT PASSWORD</label>
                      <input autoComplete="current-password" value={oldPassword} onChange={(e) => { this.setState({ oldPassword: e.target.value }) }} required placeholder="***************" type="password" name="oldPassword" id="oldPassword"
                        className="block w-full h-12 px-6 border border-indigo-400 rounded-md mt-2 text-lg font-fira font-medium text-gray-600" />
                    </div>

                    <div className="mt-5">
                      <label htmlFor="newPassword" className="text-sm font-fira font-medium">NEW PASSWORD</label>
                      <input autoComplete="new-password" value={newPassword} onChange={(e) => { this.setState({ newPassword: e.target.value }) }} required placeholder="***************" type="password" name="newPassword" id="newPassword"
                        className="block w-full h-12 px-6 border border-indigo-400 rounded-md mt-2 text-lg font-fira font-medium text-gray-600" />
                    </div>
                  </React.Fragment>
                }

                <div className="mt-5">
                  <label htmlFor="toggleA" className="flex items-center cursor-pointer">
                    <div className="relative toggleA">
                      <input type="checkbox" id="toggleA" checked={soundNotificationOn} onChange={(e) => { this.setState({ soundNotificationOn: e.target.checked }) }} className="sr-only" />
                      <div className="block bg-indigo-300 w-14 h-7 rounded-full"></div>
                      <div className="dotA absolute left-1 top-1 bg-white w-6 h-5 rounded-full transition"></div>
                    </div>
                    <div className="ml-3 text-gray-700 text-xl font-fira">
                      Notification sound on new message
                    </div>
                  </label>
                </div>

                <div className="mt-5">
                  <label htmlFor="toggleB" className="flex items-center cursor-pointer">
                    <div className="relative toggleB">
                      <input type="checkbox" id="toggleB" checked={plan !== "free" ? emailNotificationOn : false} onChange={(e) => { this.setState({ emailNotificationOn: e.target.checked }) }} className="sr-only" />
                      <div className="block bg-indigo-300 w-14 h-7 rounded-full"></div>
                      <div className="dotB absolute left-1 top-1 bg-white w-6 h-5 rounded-full transition"></div>
                    </div>
                    <div className="ml-3 text-gray-700 text-xl font-fira">
                      Email notification on new message &nbsp;
                      <a href="https://docs.katario.com/help/cant-turn-email-notifications-on" target="_blank" rel="noreferrer" >
                        <span className="text-xs text-indigo-500">Can't turn it on?</span>
                      </a>
                    </div>
                  </label>
                </div>

                <div className="mt-5">
                  <label htmlFor="toggleC" className="flex items-center cursor-pointer">
                    <div className="relative toggleC">
                      <input type="checkbox" id="toggleC" checked={(plan !== "free" && emailNotificationOn) ? smsContentInNotification : false} onChange={(e) => { this.setState({ smsContentInNotification: e.target.checked }) }} className="sr-only" />
                      <div className="block bg-indigo-300 w-14 h-7 rounded-full"></div>
                      <div className="dotC absolute left-1 top-1 bg-white w-6 h-5 rounded-full transition"></div>
                    </div>
                    <div className="ml-3 text-gray-700 text-xl font-fira">
                      Show message content in email notification &nbsp;
                    </div>
                  </label>
                </div>

                <div className="mt-6 space-x-4 text-right">
                  <button
                    type="button"
                    disabled={updating}
                    onClick={this.updateAccountDetails}
                    className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                    <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${updating ? '' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Save changes
                  </button>

                  <button
                    type="button"
                    disabled={updating}
                    onClick={() => {
                      this.showConfirm('Are you sure to delete your account? Any Profile or Agent associated with this account will be deleted as well. This can\'t be undone.', this.deleteAccount);
                    }}
                    className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                    <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${updating ? '' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Delete my account
                  </button>
                </div>
              </div>

            </form>

          </div>
        }

        {/* ERROR MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showErrorModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showErrorModal: false, errorMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-red-500">{errorMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showErrorModal: false, errorMessage: "" });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* SUCCESS MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showSuccessModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showSuccessModal: false, successMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-green-500">{successMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showSuccessModal: false, successMessage: "" });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* INFO MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showInfoModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showInfoModal: false, infoMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-indigo-500">{infoMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showInfoModal: false, infoMessage: "" });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* CONFIRM MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showConfirmModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded border-b">
              <span className="text-lg font-fira font-medium text-indigo-500">{confirmMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showConfirmModal: false, confirmMessage: "", confirmCallback: {} });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
            <p className="text-right space-x-4 pt-2 pr-4">
              <button
                disabled={updating}
                type="button"
                onClick={() => {
                  this.setState({ showConfirmModal: false, confirmMessage: "", confirmCallback: {} });
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Cancel
              </button>

              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  confirmCallback();
                  setTimeout(() => {
                    this.setState({ showConfirmModal: false, confirmMessage: "", confirmCallback: {} });
                  }, 300);
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Confirm
              </button>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react';
import {
  Link,
} from "react-router-dom";

import LogoSmall from '../assets/logo-small.svg';

export default class Header extends Component {
  render() {

    return (
      <div className="w-full bg-white flex justify-between p-5 space-x-10 fixed top-0" style={{ minWidth: '1040px'}}>
        {/* LOGO AND EMAIL AND SEARCH */}
        <div className="flex justify-between flex-1">
          {/* LOGO AND EMAIL */}
          <div className="flex space-x-2 w-96">
            <Link to="/chats" onClick={() => this.props.setPage("chats")}>
              <p className="">
                <img className="w-14" src={LogoSmall} alt="Katario logo" />
              </p>
            </Link>
            <div>
              <p className="text-xl font-bold font-nunito text-indigo-900">Katario</p>
              <Link to="/account" onClick={() => this.props.setPage("account")}>
                <p className="font-medium text-gray-400 font-fira">{this.props.me.email}</p>
              </Link>
            </div>
          </div>
          {/* SEARCH */}
          <div className="w-full">
            {/* <div className="relative">
              <div className="absolute top-4 left-3"> <i className="fa fa-search text-indigo-300 z-20 hover:text-gray-500"></i> </div>
              <input type="search" className="h-14 px-10 rounded z-0 focus:shadow focus:outline-none w-full font-source font-medium placeholder-gray-400 text-indigo-400 text-lg" placeholder="Search for contacts, groups, chats" />
            </div> */}
          </div>
        </div>
        {/* RIGHT BELL AND PROFILE */}
        <div className="flex justify-end space-x-4">
          <p className="p-3 cursor-pointer">
          <Link to="/chats" onClick={() => this.props.setPage("chats")}>
          <span className={`-ml-1 relative inline-block float-right rounded-full h-2 w-2 ${this.props.unreadCount ? '' : 'opacity-0'} bg-purple-500`}></span>
            <span className="material-icons-outlined">
              notifications
            </span>
          </Link>
          </p>
          <p className="cursor-pointer">
            <Link to="/account" onClick={() => this.props.setPage("account")}>
              <i className="fas fa-user-circle text-5xl rounded-full text-gray-200"></i>
            </Link>
          </p>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react';
import {
  Link,
} from "react-router-dom";
import { io } from "socket.io-client";
import moment from 'moment';

import Axios from '../api/axios';

import CONSTANTS from '../constants';

import audio from '../assets/tone.mp3';

const GREY_IMGAE_DATA_URI = "data:image/svg+xml,%3Csvg width='72' height='64' viewBox='0 0 72 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='72' height='64' fill='%23C4C4C4'/%3E%3C/svg%3E%0A";

export default class Chats extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      status: false,
      plan: "free",
      showErrorModal: false,
      showSuccessModal: false,
      showInfoModal: false,
      showConfirmModal: false,
      showProfilesModal: false,
      errorMessage: '',
      successMessage: '',
      infoMessage: '',
      confirmMessage: '',
      updating: false,
      confirmCallback: {},
      confirmCallbackData: {},
      error: false,
      profiles: [],
      profilesCount: 0,
      moved: false,
      allChats: [],
      openedChatId: '',
      searchText: '',
      openedChatMessages: [],
      currentMessage: '',
      currentMediaUrl: '',
      showAttachImage: false,
      editingMediaUrl: '',
      brokenImage: true,
      altSrc: '',
      working: false,
      showSchedule: false,
      scheduleDate: '',
      minDate: null,
      maxDate: null,
      displayGroup: false,
      displayContact: false,
      currentChat: {},
      currentChatIndex: null,
      soundNotificationOn: true,
    };

    this.messagesEnd = React.createRef();
    this.schedulerRef = React.createRef();

    this.audioToPlay = new Audio(audio);

    this.socket = io(CONSTANTS.NOTIFICATION_URL);
    this.socket2 = io(CONSTANTS.API_URL);
  }

  componentDidMount() {
    this.loadChats();
    this.setListeners();
    this.updateUnreadCount();
  }

  setListeners = () => {
    this.socket.off();
    this.socket2.off();

    this.socket.on("connect", () => { });
    this.socket2.on("connect", () => { });

    this.socket.on(`${this.props.profileId}-update`, ({ profileId, messageId, status }) => {
      if (!profileId || !messageId || !status || profileId !== this.props.profileId) return;

      const { openedChatMessages } = this.state;
      this.setState({
        openedChatMessages: openedChatMessages.map((m) => {
          if (m._id !== messageId) return m;
          m.status = status;
          return m;
        })
      })
    });

    this.socket.on(`${this.props.profileId}-new`, ({ profileId, message, phoneNumber, chat }) => {
      if (!profileId || !message || profileId !== this.props.profileId || (!phoneNumber && !chat)) return;


      const { openedChatMessages, allChats, openedChatId, soundNotificationOn } = this.state;

      const currentChatIndex = allChats.map((c, index) => c._id === openedChatId ? index : null).filter((c) => c != null)[0];

      

      if (chat && Object.keys(chat).length) {
        if (soundNotificationOn) {
          this.audioToPlay.play();
        }

        this.updateUnreadCount(1);

        this.setState({ allChats: [{ ...chat, lastMessage: { fromMe: false, text: message.body } }, ...allChats] });
      } else if (/^\+[1-9]\d{6,14}$/.test(phoneNumber)) {
        if (soundNotificationOn) {
          this.audioToPlay.play();
        }

        this.updateUnreadCount(1);

        this.setState({
          allChats: allChats.map((c) => {
            if (c.phoneNumber === phoneNumber) {
              c.unreadCount++;
              c.lastMessageTimestamp = Date.now();
              c.messageCount++;
              c.lastMessage = { fromMe: false, text: message.body };
            }
            return c;
          }).sort((ca, cb) => cb.lastMessageTimestamp - ca.lastMessageTimestamp),
          openedChatMessages: allChats[currentChatIndex].phoneNumber === phoneNumber ? [...openedChatMessages, message] : openedChatMessages
        });
      }
    });

    this.socket2.on(`${this.props.profileId}-bulk-status`, ({ status, id }) => {
      // console.log({ status, id });
      if (!status || !id) return;

      const { openedChatMessages } = this.state;
      this.setState({
        openedChatMessages: openedChatMessages.map((m) => {
          if (String(m._id) === String(id)) {
            m.status = status;
            return m;
          } else return m;
        })
      })
    });

    this.socket2.on(`${this.props.profileId}-bulk-update`, ({ sent, id }) => {
      // console.log({ sent, id });
      if (typeof sent !== 'boolean' || !id) return;

      // console.log({ sent, id });

      const { openedChatMessages } = this.state;
      this.setState({
        openedChatMessages: openedChatMessages.map((m) => {
          if (String(m._id) === String(id)) {
            if (sent) {
              m.sent++;
            } else {
              m.notSent++;
            }
            // console.log(m);
            return m;
          } else return m;
        })
      })
    });
  };

  updateUnreadCount = (count_ = false) => {
    const { allChats } = this.state;

    const count = count_ ? count_ : allChats.filter((c) => !c.isGroup).map((c) => c.unreadCount).reduce((a, b) => a + b, 0);

    this.props.setUnreadCount(count);
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedProfileIndex !== prevProps.selectedProfileIndex) {
      this.loadChats();
      this.setListeners();
    }
  }

  componentWillUnmount() {
    this.socket.off();
    this.socket2.off();
  }

  showError = (msg, delay = 10) => {
    this.setState({
      showErrorModal: true,
      errorMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showErrorModal: false,
          errorMessage: '',
        })
      }, delay * 1000);
    })
  }

  showSuccess = (msg, delay = 10) => {
    this.setState({
      showSuccessModal: true,
      successMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showSuccessModal: false,
          successMessage: '',
        })
      }, delay * 1000);
    })
  }

  showInfo = (msg, delay = 10) => {
    this.setState({
      showInfoModal: true,
      infoMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showInfoModal: false,
          infoMessage: '',
        })
      }, delay * 1000);
    })
  }

  showConfirm = (msg, callback, data) => {
    this.setState({
      showConfirmModal: true,
      confirmMessage: msg,
      confirmCallback: callback,
      confirmCallbackData: data,
    })
  }

  loadChats = () => {
    this.setState({ updating: true });

    Axios({
      url: `/chats/all/${this.props.profileId}`,
      method: 'get',
      headers: { 'x-auth': this.props.id },
    })
      .then((res) => {

        this.setState({
          allChats: res.data.allChats,
          updating: false,
          ready: true,
          plan: res.data.plan,
          status: res.data.status,
          profiles: res.data.profiles,
          soundNotificationOn: res.data.soundNotificationOn,
        });

        if (res.data.allChats.length) {
          const chatToOpen = new URLSearchParams(window.location.search).get('contact') ?? new URLSearchParams(window.location.search).get('group');

          if (chatToOpen) {
            this.updateOpenedChat(chatToOpen);
          } else {
            this.updateOpenedChat(res.data.allChats[0]._id);
          }

        }

      })
      .catch(({ response }) => {

        this.setState({ updating: false, error: true });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  toggleBlockedContact = (contactId) => {
    const { allChats } = this.state;

    this.setState({ updating: true });
    Axios({
      url: `/contacts/toggleBlock/${this.props.profileId}/${contactId}`,
      method: 'get',
      headers: { 'x-auth': this.props.id },
    })
      .then((_res) => {

        this.setState({
          allChats: allChats.map((c) => {
            if (c._id === contactId) {
              c.blocked = !c.blocked;
            }
            return c;
          }),
          updating: false,
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  updateOpenedChat = (chatId) => {
    const { allChats } = this.state;


    let currentChatIndex = allChats.map((c, index) => c._id === chatId ? index : null).filter((c) => c != null)[0];

    if (!currentChatIndex) {
      currentChatIndex = 0;
    }

    this.setState({ updating: true });

    const currentChat = allChats[currentChatIndex];

    Axios({
      url: `/chats/messages/${this.props.profileId}/${currentChat.phoneNumber ?? currentChat._id}?read=${currentChat.unreadCount > 0}`,
      method: 'get',
      headers: { 'x-auth': this.props.id },
    })
      .then((res) => {

        allChats[currentChatIndex].unreadCount = 0;
        this.setState({
          openedChatMessages: res.data.messages,
          openedChatId: currentChat._id,
          allChats,
          currentMessage: '',
          currentMediaUrl: '',
          updating: false,
          currentChatIndex,
        }, () => {
          setTimeout(this.scrollToBottomOfChat, 1000);
          this.updateUnreadCount();
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  isPhoneNumber = (text) => {
    const res1 = text[0] === '+' && !Number.isNaN(parseInt(text.slice(1), 10));
    return res1;
  }

  notInChats = (text) => {
    const { allChats } = this.state;
    const res2 = allChats.filter((a) => a.phoneNumber && a.phoneNumber.includes(text)).length === 0;
    return res2;
  }

  createContact = () => {
    const {
      status,
      searchText,
    } = this.state;

    if (!status) {
      return this.showError('Your subscription is inactive, update your plan to continue using the product!', 10);
    }

    this.setState({ updating: true });
    Axios({
      url: `/contacts/createAlt`,
      method: 'post',
      headers: { 'x-auth': this.props.id },
      data: { profileId: this.props.profileId, phoneNumber: searchText },
    })
      .then((res) => {

        this.setState({
          allChats: res.data.allChats,
          searchText: '',
          updating: false,
        });

        this.updateOpenedChat(res.data.contact._id);

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  sendMessage = () => {
    const { allChats, currentMessage, currentMediaUrl, openedChatMessages, openedChatId, status, plan } = this.state;

    if (!status) {
      return this.showError('Your subscription is inactive, update your plan to continue using the product!', 10);
    }

    if (!currentMessage.trim().length) return;

    const currentChatIndex = allChats.map((c, index) => c._id === openedChatId ? index : null).filter((c) => c != null)[0];

    if (plan !== 'business' && allChats[currentChatIndex].isGroup) {
      return this.showError('Update your plan to send bulk messages!', 10);
    }

    this.setState({ working: true, updating: true }, () => {


      Axios({
        url: `/chats/sendmessage`,
        method: 'post',
        data: {
          profileId: this.props.profileId,
          recipientId: allChats[currentChatIndex]._id,
          message: currentMessage,
          mediaUrl: currentMediaUrl,
          isGroup: allChats[currentChatIndex].isGroup ? true : false,
        },
        headers: { 'x-auth': this.props.id },
      })
        .then((res) => {
          //// console.log(res);

          allChats[currentChatIndex].messageCount++;
          allChats[currentChatIndex].lastMessage = {
            text: res.data.message.body,
            fromMe: true
          };
          allChats[currentChatIndex].lastMessageTimestamp = Date.now();
          openedChatMessages.push(res.data.message);

          this.setState({
            openedChatMessages, currentMessage: '', currentMediaUrl: '', working: false, allChats, updating: false,
          }, this.scrollToBottomOfChat);

        })
        .catch(({ response }) => {

          this.setState({ updating: false, working: false });

          if (response) {
            const { status } = response;

            if (status === 401) {
              window.localStorage.removeItem('id');
              window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
            } else if (status === 429) {
              this.showError("Please slow down! Wait 2 minutes and try again");
            } else if (status === 400) {
              this.showError(response.data.errors[0].msg);
            } else if (status === 500) {
              this.showError(response.data.message);
            } else {
              this.showError("An unexpected error occured!");
            }
          } else {
            this.showError("Network error!");
          }

        });

    });
  }

  attachImage = () => {
    const { editingMediaUrl } = this.state;
    this.setState({ currentMediaUrl: editingMediaUrl, showAttachImage: false });
  }

  removeAttachment = () => {
    this.setState({ currentMediaUrl: '' });
  }

  scrollToBottomOfChat = () => {
    if (this.messagesEnd.current)
      this.messagesEnd.current.scrollIntoView({ behavior: "smooth" });
  }

  scheduleMessage = () => {
    const { allChats, currentMessage, currentMediaUrl, openedChatMessages, openedChatId, scheduleDate, status, plan } = this.state;

    if (!currentMessage.trim().length) return;

    if (!status) {
      return this.showError('Your subscription is inactive, update your plan to continue using the product!', 10);
    }

    const currentChatIndex = allChats.map((c, index) => c._id === openedChatId ? index : null).filter((c) => c != null)[0];

    if (plan !== 'business') {
      return this.showError('Update your plan to schedule messages!', 10);
    }

    console.log(!!allChats[currentChatIndex].isGroup);

    this.setState({ working: true, updating: true }, () => {

      Axios({
        url: `/chats/schedule`,
        method: 'post',
        data: {
          profileId: this.props.profileId,
          recipientId: allChats[currentChatIndex]._id,
          message: currentMessage,
          mediaUrl: currentMediaUrl,
          date: new Date(scheduleDate).toISOString(),
          isGroup: !!allChats[currentChatIndex].isGroup,
        },
        headers: { 'x-auth': this.props.id },
      })
        .then((res) => {
          //// console.log(res);

          allChats[currentChatIndex].messageCount++;
          openedChatMessages.push(res.data.message);

          this.setState({
            openedChatMessages, currentMessage: '', currentMediaUrl: '', scheduleDate: null, working: false, allChats, showSchedule: false, updating: false,
          }, this.scrollToBottomOfChat);

        })
        .catch(({ response }) => {

          this.setState({ updating: false, working: false });

          if (response) {
            const { status } = response;

            if (status === 401) {
              window.localStorage.removeItem('id');
              window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
            } else if (status === 429) {
              this.showError("Please slow down! Wait 2 minutes and try again");
            } else if (status === 400) {
              this.showError(response.data.errors[0].msg);
            } else if (status === 500) {
              this.showError(response.data.message);
            } else {
              this.showError("An unexpected error occured!");
            }
          } else {
            this.showError("Network error!");
          }

        });

    });
  }

  deleteScheduledMessage = (messageId) => {
    const { allChats, openedChatMessages, openedChatId } = this.state;

    const currentChatIndex = allChats.map((c, index) => c._id === openedChatId ? index : null).filter((c) => c != null)[0];

    if (!messageId) return;

    this.setState({ working: true, updating: true });

    Axios({
      url: `/chats/delete/${this.props.profileId}/${messageId}`,
      method: 'get',
      headers: { 'x-auth': this.props.id }
    })
      .then((res) => {
        //// console.log(res);

        allChats[currentChatIndex].messageCount--;

        this.setState({
          openedChatMessages: openedChatMessages.filter((m) => m._id !== messageId), allChats,
          working: false, updating: false,
        }, this.scrollToBottomOfChat);

      })
      .catch(({ response }) => {

        this.setState({ updating: false, working: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  deleteScheduledMessageAlt = (messageId) => {
    const { allChats, openedChatMessages, openedChatId } = this.state;

    const currentChatIndex = allChats.map((c, index) => c._id === openedChatId ? index : null).filter((c) => c != null)[0];

    if (!messageId) return;

    this.setState({ working: true, updating: true });

    Axios({
      url: `/chats/deleteAlt/${this.props.profileId}/${messageId}`,
      method: 'get',
      headers: { 'x-auth': this.props.id }
    })
      .then((res) => {
        //// console.log(res);

        allChats[currentChatIndex].messageCount--;

        this.setState({
          openedChatMessages: openedChatMessages.filter((m) => m._id !== messageId), allChats,
          working: false, updating: false,
        }, this.scrollToBottomOfChat);

      })
      .catch(({ response }) => {

        this.setState({ updating: false, working: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  displayScheduler = () => {
    const { currentMessage } = this.state;

    if (!currentMessage.trim().length) return;

    const now = Date.now();
    const minDate = new Date(now).toISOString().split('.')[0];
    const maxDate = new Date(now + 1 * 7 * 24 * 60 * 60 * 1000).toISOString().split('.')[0];

    this.setState({ showSchedule: true, minDate, maxDate });

  }

  render() {
    const {
      ready, showSuccessModal, showInfoModal, showErrorModal,
      showConfirmModal, successMessage, infoMessage, currentMessage,
      errorMessage, confirmMessage, updating, confirmCallback,
      confirmCallbackData, searchText, openedChatMessages, showAttachImage,
      error, allChats, displayContact, displayGroup, currentMediaUrl,
      profiles, currentChatIndex, openedChatId, working, editingMediaUrl, showSchedule,
      minDate, maxDate, scheduleDate,
    } = this.state;

    //const currentChatIndex = allChats.map((c, index) => c._id === openedChatId ? index : null).filter((c) => c != null)[0];

    const currentChat = allChats[currentChatIndex];

    return (
      <div>
        {(ready || error) || <div className="mx-auto max-w-billing">
          <h1 className="text-2xl font-nunito font-bold">Loading...</h1>
        </div>}
        {
          error && !ready && <p className='p-4 bg-white rounded-md mt-2 font-fira font-medium text-gray-7 mx-auto max-w-billing'>An unexpected error occured, reload the page!</p>
        }
        {
          ready && <div className={`flex mx-20 bg-white rounded min-h-80vh max-h-80vh overflow-hidden ${updating ? 'opacity-60' : ''}`}>
            <div className="w-80 border-r">
              <div className="flex px-4 pt-4">
                <span className="text-lg font-fira" style={{ alignSelf: "center" }}>Profile &nbsp;</span>
                <span className="flex-1">
                  <select value={this.props.selectedProfileIndex} onChange={(e) => this.props.setSelectedProfileIndex(parseInt(e.target.value, 10))} className="w-full h-11 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600">
                    {
                      profiles.map((p, i) => (
                        <option key={i} value={i}>{p.name}</option>
                      ))
                    }
                  </select>
                </span>
              </div>
              <div className="mt-5 px-4">
                <p className="text-xl font-fira font-semibold">Messages &nbsp;
                  <span className="text-lg font-medium text-indigo-800 bg-indigo-200 p-1 px-2 rounded-lg">
                    {allChats.filter((c) => !c.isGroup).map((c) => c.unreadCount).reduce((a, b) => a + b, 0)}
                  </span>
                </p>
              </div>
              <div className="mt-5 flex px-4">
                <span className="pl-2 material-icons-outlined text-3xl text-indigo-800 z-10 self-center">search</span>
                <input
                  value={searchText} onChange={(e) => this.setState({ searchText: e.target.value.toLocaleLowerCase() })}
                  type="search" className="border-2 rounded-md text-xl font-fira py-2 pl-12 pr-2 -ml-10 outline-none focus:border-indigo-600" placeholder="Search" />
              </div>
              <div className="mt-2 overflow-y-auto">

                {this.isPhoneNumber(searchText) && this.notInChats(searchText) && (
                  <ul>
                    <li
                      onClick={this.createContact}
                      className="border-t border-b border-gray-200 p-2 flex justify-between hover:bg-purple-50 cursor-pointer font-poppins"
                    >
                      <p className="flex">
                        <i className='fa fa-user-circle fa-3x text-indigo-600 inline-block'></i>
                        &nbsp;
                        <span className="leading-11 self-center">
                          {searchText}
                        </span>
                      </p>
                    </li>
                  </ul>
                )}

                <ul className="max-h-60vh">
                  {allChats.length > 0 && allChats.map((c, index) => {
                    if (!c.name.toLocaleLowerCase().includes(searchText) && !c.phoneNumber?.toLocaleLowerCase().includes(searchText)) return <React.Fragment key={c._id}></React.Fragment>
                    return (
                      <li onClick={() => this.updateOpenedChat(c._id)} key={c._id}>
                        <div className={`py-4 border-b ${c._id === openedChatId ? 'bg-purple-100' : ''} px-4 cursor-pointer hover:bg-purple-50`}>
                          <div className="flex justify-between">
                            <div className="flex">
                              <span className={`w-2 h-2 rounded-full bg-purple-600 self-center mr-1 ${c.unreadCount ? '' : 'opacity-0'}`}></span>
                              <p className="mr-2">
                                {c.isGroup || (
                                  c.profilePictureUrl
                                    ? <img loading="lazy" className="h-11 w-11 rounded-full inline" src={c.profilePictureUrl} onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }} alt="profile" />
                                    : <i className='fa fa-user-circle fa-3x text-indigo-600 inline-block'></i>
                                )}

                                {
                                  c.isGroup && (
                                    c.groupImageUrl
                                      ? <img loading="lazy" className="h-11 w-11 rounded-full bg-cover inline" src={c.groupImageUrl} onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }} alt="profile" />
                                      : <i className='fa fa-users fa-2x text-indigo-600 inline-block leading-11'></i>
                                  )
                                }
                              </p>
                              <div className="self-center">
                                <p className="font-fira font-medium text-gray-800">{c.name.slice(0, 15)} {c.isGroup ? '(G)' : ''}</p>
                                <p className="text-sm font-fira font-medium text-indigo-800">
                                  {
                                    c.isGroup || c.phoneNumber
                                  }
                                  {
                                    c.isGroup && `${c.participants.length} participants`
                                  }
                                </p>
                              </div>
                            </div>
                            <span className="font-roboto font-medium text-gray-700">
                              {/* {c.lastMessageTimestamp ? `${moment(new Date(c.lastMessageTimestamp)).fromNow() === "a few seconds ago" ? "now" : moment(new Date(c.lastMessageTimestamp)).fromNow()}` : ''} */}
                            </span>
                          </div>
                          <div className="mt-4 ml-2 font-roboto font-medium text-indigo-900 text-sm">
                            <p>{c.lastMessage?.fromMe ? 'You: ' : ''} {c.lastMessage?.text?.slice(0, 25)}</p>
                          </div>
                        </div>
                      </li>
                    )
                  })}
                  <p className="py-6"></p>
                </ul>


              </div>
            </div>
            { allChats.length >0 && <div className="w-full flex flex-col">
              <div className="py-4 px-5 flex justify-between border-b">
                <div className="flex">
                  <p>
                    {allChats[currentChatIndex]?.isGroup || (
                      allChats[currentChatIndex]?.profilePictureUrl
                        ? <img loading="lazy" className="h-16 w-16 rounded-full inline" src={allChats[currentChatIndex]?.profilePictureUrl} onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }} alt="profile" />
                        : <i className='fa fa-user-circle fa-4x text-indigo-600 inline-block'></i>
                    )}

                    {
                      allChats[currentChatIndex]?.isGroup && (
                        allChats[currentChatIndex]?.groupImageUrl
                          ? <img loading="lazy" className="h-16 w-16 rounded-full bg-cover inline" src={allChats[currentChatIndex]?.groupImageUrl} onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }} alt="profile" />
                          : <i className='fa fa-users fa-3x text-indigo-600 inline-block leading-11'></i>
                      )
                    }
                  </p>
                  <div className="ml-3 self-center">
                    <p className="font-fira text-xl font-semibold text-gray-700">{allChats[currentChatIndex]?.name}</p>
                    <p className="font-fira text-lg font-medium text-indigo-800">{allChats[currentChatIndex]?.phoneNumber}</p>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => {
                      if (allChats[currentChatIndex]?.isGroup === true) {
                        this.setState({ displayGroup: true });
                      } else {
                        this.setState({ displayContact: true });
                      }
                    }}
                    className="bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200">View profile</button>
                </div>
              </div>

              <div className="flex-grow overflow-y-auto px-4">

                {openedChatMessages.length >= 0 && openedChatMessages.map((message) => {

                  if (!message.fromMe) {
                    return (
                      <div className="flex space-x-3 pt-4" key={message._id}>
                        <div>
                          {allChats[currentChatIndex]?.isGroup || (
                            allChats[currentChatIndex]?.profilePictureUrl
                              ? <img loading="lazy" className="h-12 w-12 rounded-full inline" src={allChats[currentChatIndex]?.profilePictureUrl} onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }} alt="profile" />
                              : <i className='fa fa-user-circle fa-3x text-indigo-600 inline-block'></i>
                          )}
                        </div>
                        <div className="max-w-2xl">
                          <p className="flex justify-between space-x-3">
                            <span className="font-fira font-medium text-indigo-800">{allChats[currentChatIndex]?.name}</span>
                            <span className="font-roboto text-sm">{moment(new Date(new Date(message.createdAt))).fromNow() === "a few seconds ago" ? "now" : moment(new Date(new Date(message.createdAt))).fromNow()}</span>
                          </p>
                          <p className="bg-gray-200 font-roboto font-medium p-3 rounded-md rounded-tl-none">
                            {message.body}
                            {message.mediaUrl && (
                              <a target="_blank" rel="noreferrer" href={message.mediaUrl}>
                                <img
                                  className="mt-2 max-h-72 overflow-hidden"
                                  alt="media mms"
                                  src={message.mediaUrl}
                                  onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }}
                                />
                              </a>
                            )}
                          </p>
                        </div>
                      </div>
                    );
                  } else {

                    return (
                      <div className="flex justify-end" key={message._id}>
                        <div className="flex space-x-3 pt-4">
                          <div className="max-w-2xl">
                            <p className="flex justify-between">
                              <span className="font-fira font-medium text-indigo-800"></span>
                              <span className="font-roboto text-sm"><span className="text-indigo-600">{message.status}</span>, {moment(new Date(new Date(message.updatedAt))).fromNow() === "a few seconds ago" ? "now" : moment(new Date(new Date(message.createdAt))).fromNow()}</span>
                            </p>
                            <p
                              title={message.scheduled ? `Scheduled for ${new Date(message.scheduleTime).toLocaleString()}` : ''}
                            className="bg-indigo-500 text-white font-roboto font-medium p-3 rounded-md rounded-tr-none">
                              {message.body}
                              {message.mediaUrl && (
                                <a target="_blank" rel="noreferrer" href={message.mediaUrl}>
                                  <img
                                    className="mt-2 max-h-72 overflow-hidden"
                                    alt="media mms"
                                    src={message.mediaUrl}
                                    onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }}
                                  />
                                </a>
                              )}
                            </p>
                            <p className={`text-right ${(message.scheduled && message.status === 'scheduled' )? '' : 'hidden'}`}>
                              <button
                                disabled={message.status !== 'scheduled' || !message.scheduled}
                                onClick={() => allChats[currentChatIndex]?.isGroup ? this.deleteScheduledMessage(message._id) : this.deleteScheduledMessageAlt(message._id)}
                                className="">delete</button>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  }

                })}

                {openedChatMessages.length === 0 && currentChat?.isGroup && (
                  <div className="p-8 border border-blue-100">
                    <p className="text-center font-fira">This is not a consersation group but a bulk message group, every participant will receive a private message.</p>
                  </div>
                )}

                {openedChatMessages.length === 0 && !currentChat?.isGroup && (
                  <div className="p-8 border border-blue-100">
                    <p className="text-center font-fira">This is your chat with <span className="text-blue-900 font-bold">{currentChat?.name}</span>, send a message to start the conversation.</p>
                  </div>
                )}

                <p className="h-4" ref={this.messagesEnd}></p>

              </div>

              <div>
                {currentMediaUrl.length > 0 && (
                  <div className="flex justify-between border border-gray-300 px-2 py-2 font-fira mx-4 bg-indigo-50">
                    <span className="cursor-pointer" onClick={() => this.setState({ showAttachImage: true })}>{currentMediaUrl.slice(0, 40)}...</span>
                    <span className="cursor-pointer" onClick={() => this.removeAttachment()}>x</span>
                  </div>
                )}
                <div className="pl-4 pr-3 pb-2 font-roboto flex">
                  <textarea
                    value={currentMessage}

                    onChange={(e) => this.setState({ currentMessage: e.target.value })}
                    rows="3" className="w-full h-full pl-6 pr-48 py-4 rounded-md border border-indigo-200 outline-none resize-none flex-grow" placeholder="Send a message"></textarea>
                  <p className="space-x-1 z-10 w-44 self-center" style={{ marginLeft: '-10.8rem' }}>
                    <button onClick={() => this.setState({ showAttachImage: true })} className="material-icons-outlined bg-indigo-500 px-3 py-3 text-white rounded font-bold hover:opacity-80 duration-200 align-middle">attachment</button>
                    <button onClick={() => this.sendMessage()} disabled={working || updating} className="material-icons-outlined bg-indigo-500 px-3 py-3 text-white rounded font-bold hover:opacity-80 duration-200 align-middle">send</button>
                    <button onClick={() => this.displayScheduler()} className="material-icons-outlined bg-indigo-500 px-3 py-3 text-white rounded font-bold hover:opacity-80 duration-200 align-middle" title="Schedule">date_range</button>
                  </p>
                </div>
                <p className="px-4 pb-2 text-sm font-fira">Unable to send or receive messages? <a className="text-indigo-600" target="_blank" rel="noreferrer" href="https://docs.katario.com/help/cant-send-messages">Read this</a> or <a className="text-indigo-600" target="_blank" rel="noreferrer" href="https://katario.com/contact-us">Contact us</a></p>
              </div>
            </div>}

            {
              allChats.length === 0 && <p className="w-full text-center pt-6 font-fira text-lg">
                <Link className="text-indigo-600" to='/contacts' onClick={()=>this.props.setPage('contacts')}>Create a contact</Link> or type and choose a phone number in the search bar to start a conversation.
              </p>
            }
          </div>
        }
        {/* ERROR MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showErrorModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showErrorModal: false, errorMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-red-500">{errorMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showErrorModal: false, errorMessage: '' });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* SUCCESS MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showSuccessModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showSuccessModal: false, successMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-green-500">{successMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showSuccessModal: false, successMessage: '' });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* INFO MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showInfoModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showInfoModal: false, infoMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-blue-500">{infoMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showInfoModal: false, infoMessage: '' });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* CONFIRM MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showConfirmModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
          }}
        >
          <div className="modal bg-white max-w-lg shadow-sm pb-2 mx-auto relative rounded" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded border-b">
              <span className="text-lg font-fira font-medium text-indigo-500">{confirmMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
            <p className="text-right space-x-4 pt-2 pr-4">
              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Cancel
              </button>

              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  confirmCallback(confirmCallbackData);
                  setTimeout(() => {
                    this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
                  }, 300);
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Confirm
              </button>
            </p>
          </div>
        </div>

        {/* CONTACT DISPLAY MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-10 ${displayContact ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ displayContact: false, displayedContactIndex: null });
          }}
        >
          <div className="modal bg-white max-w-lg shadow-sm py-2 mx-auto relative rounded" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>

            <div className="bg-white pb-2 sm:pb-1">
              <div className="font-fira">
                {Number.isInteger(currentChatIndex) && allChats[currentChatIndex] && <div className="">
                  <div className="bg-indigo-900 m-4 p-7 rounded-xl pb-16">
                    <p className="text-center">
                      {allChats[currentChatIndex].profilePictureUrl ? <img className="h-20 w-20 rounded-lg inline-block text-center bg-cover" onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }} src={allChats[currentChatIndex].profilePictureUrl} alt="pp" />
                        : <span className="material-icons-outlined text-7xl text-white">account_circle</span>
                      }
                    </p>
                    <p className="text-center text-xl font-medium text-white mt-3 font-roboto">{allChats[currentChatIndex].name}</p>
                    <p className="text-center text-lg text-white mt-2 font-roboto text-opacity-60">
                      <span className="text-white text-opacity-90">{allChats[currentChatIndex].title ?? '-'}</span>, &nbsp;
                      {allChats[currentChatIndex].company ?? '-'}</p>
                    <p className="text-center text-base text-white text-opacity-60">{allChats[currentChatIndex].email ?? '-'}</p>
                  </div>
                  <div className="bg-white m-8 -mt-12 p-5 rounded-xl pb-30 shadow-md">
                    <p className="text-center text-indigo-800 text-lg font-medium text-blue mt-2 font-roboto">{allChats[currentChatIndex].phoneNumber}</p>
                    <p className="text-center mt-3">
                      {allChats[currentChatIndex].labels
                        && allChats[currentChatIndex].labels.map((label, index) => {
                          return <span
                            key={index}
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indgio-800"
                          >
                            {label}
                          </span>
                        })}
                    </p>
                  </div>
                  <div className="mt-2 p-2 px-8 text-center">
                    <label htmlFor="toggleB" className="flex items-center cursor-pointer text-center">
                      <div className="relative toggleB">
                        <input type="checkbox" id="toggleB" checked={allChats[currentChatIndex]?.blocked} onChange={(e) => { this.toggleBlockedContact(allChats[currentChatIndex]._id) }} className="sr-only" />
                        <div className="block bg-indigo-300 w-14 h-7 rounded-full"></div>
                        <div className="dotB absolute left-1 top-1 bg-white w-6 h-5 rounded-full transition"></div>
                      </div>
                      <div className="ml-3 text-gray-700 text-xl font-fira text-center">
                        BLACKLISTED &nbsp;
                        <a href="/p" target="_blank" >
                          <span className="text-xs text-indigo-500">What is this?</span>
                        </a>
                      </div>
                    </label>
                  </div>
                </div>
                }
              </div>
            </div>

            <p className="p-6 rounded text-center border-b"></p>
            <p className="flex justify-end space-x-4 pt-2 pr-4">
              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  this.setState({ displayContact: false, displayedContactIndex: null });
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Close
              </button>
            </p>
          </div>
        </div>

        {/* GROUP DISPLAY MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-10 ${displayGroup ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ displayGroup: false, displayedGroupIndex: null });
          }}
        >
          <div className="modal bg-white max-w-lg shadow-sm py-2 mx-auto relative rounded" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>

            <div className="bg-white pb-2 sm:pb-1">
              <div className="font-fira">
                {Number.isInteger(currentChatIndex) && allChats[currentChatIndex] && <div className="">
                  <div className="bg-indigo-900 m-4 p-7 rounded-xl pb-16">
                    <p className="text-center">
                      {allChats[currentChatIndex]?.groupImageUrl ? <img className="h-20 w-20 rounded-lg inline-block text-center bg-cover" onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }} src={allChats[currentChatIndex].groupImageUrl} alt="pp" />
                        : <span className="material-icons-outlined text-7xl text-white">groups</span>
                      }
                    </p>
                    <p className="text-center text-xl font-medium text-white mt-3 font-roboto">{allChats[currentChatIndex]?.name}</p>
                    <p className="text-center text-lg text-white mt-2 font-roboto text-opacity-60">
                      {allChats[currentChatIndex]?.participants?.length} Participants
                    </p>
                  </div>
                  <div className="bg-white m-8 -mt-12 p-5 rounded-xl pb-30 shadow-md">
                    <p className="text-center text-indigo-800 text-lg font-medium text-blue mt-2 font-roboto">{allChats[currentChatIndex]?.phoneNumber}</p>
                    <p className="text-center mt-3">
                      {allChats[currentChatIndex]?.labels
                        && allChats[currentChatIndex]?.labels.map((label, index) => {
                          return <span
                            key={index}
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indgio-800"
                          >
                            {label}
                          </span>
                        })}
                    </p>
                  </div>
                </div>
                }
              </div>
            </div>

            <p className="p-6 rounded text-center border-b"></p>
            <p className="flex justify-end space-x-4 pt-2 pr-4">
              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  this.setState({ displayGroup: false, displayedGroupIndex: null });
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Close
              </button>
            </p>
          </div>
        </div>

        {/* ATTACH IMAGE MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-10 ${showAttachImage ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showAttachImage: false, editingMediaUrl: '', currentMediaUrl: '' });
          }}
        >
          <div className="modal bg-white max-w-lg shadow-sm pb-2 mx-auto relative rounded" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded border-b">
              <span className="text-lg font-fira font-medium text-indigo-500">Attach an image using it's URL</span>
              <span
                onClick={() => {
                  this.setState({ showAttachImage: false });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
            <div>
              <p className="text-center mt-2">
                <img className="max-h-72 overflow-hidden inline rounded-md h-48" alt="media" src={editingMediaUrl} onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI; this.setState({ altSrc: GREY_IMGAE_DATA_URI }) }} />
              </p>
              <p className="px-5 font-fira mt-1">
                <span>Please enter the image url</span>
                <input
                  value={editingMediaUrl}
                  onChange={(e) => this.setState({ editingMediaUrl: e.target.value, altSrc: '' })}
                  placeholder="Image Url" type="url"
                  className="block w-full h-12 px-6 border border-indigo-400 rounded-md mt-2 text-lg font-fira font-medium text-gray-600"
                />
              </p>
            </div>
            <p className="text-right space-x-4 pt-2 pr-4">
              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  this.setState({ showAttachImage: false });
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Cancel
              </button>

              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  setTimeout(() => {
                    this.setState({ currentMediaUrl: editingMediaUrl, showAttachImage: false });
                  }, 300);
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Confirm
              </button>
            </p>
          </div>
        </div>

        {/* SCHEDULE MESSAGE MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-10 ${showSchedule ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showSchedule: false });
          }}
        >
          <div className="modal bg-white max-w-lg shadow-sm pb-2 mx-auto relative rounded" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded border-b">
              <span className="text-lg font-fira font-medium text-indigo-500">Choose when you want this message to be sent</span>
              <span
                onClick={() => {
                  this.setState({ showSchedule: false });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
            <div>
              <p className="px-5 font-fira mt-1 py-6">
                <input min={minDate} max={maxDate} ref={this.schedulerRef} type='datetime-local' value={scheduleDate} onChange={(e) => this.setState({ scheduleDate: e.target.value })} />
              </p>
            </div>
            <p className="text-right space-x-4 pt-2 pr-4">
              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  this.setState({ showSchedule: false });
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Cancel
              </button>

              <button
                type="button"
                disabled={updating}
                onClick={this.scheduleMessage}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Schedule
              </button>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

import {
  BrowserRouter as Router,
} from "react-router-dom";

import { message } from 'antd';

import React, { Component } from 'react';

import Header from './sections/Header';
import Sidebar from './sections/Sidebar';
import Billing from './pages/Billing';
import Chats from './pages/Chats';
import Profiles from './pages/Profiles';
import Contacts from './pages/Contacts';
import Groups from './pages/Groups';
import Agents from './pages/Agents';
import Account from './pages/Account';
import Axios from './api/axios';

import CONSTANTS from './constants';

const pages = [
  "profiles",
  "chats",
  "contacts",
  "groups",
  "agents",
  "billing",
  "account",
  "logout",
];
export default class App extends Component {
  state = {
    page: 'chats',
    connected: false,
    me: {},
    plan: "",
    ready: false,
    error: false,
    selectedProfileIndex: 0,
    profiles: [],
    sidebarHidden: false,
    unreadCount: 0,
    agentNotReady: false,
  };

  componentDidMount() {
    let { pathname } = window.location;
    pathname = pathname.split("/").filter((p) => p !== "")[0];

    if (pages.includes(pathname)) {
      this.setPage(pathname);
    } else {
      pathname = 'chats';
      this.setPage('chats');
    }

    const searchParams = new URLSearchParams(window.location.search);

    let id = searchParams.get('id');
    if (id) {
      searchParams.delete('id');
      window.localStorage.setItem('id', id);
      window.location.href = `${pathname}?${searchParams.toString()}`;
    }

    id = window.localStorage.getItem('id');

    if (id) {

      Axios({
        method: 'get',
        url: '/auth/me',
        headers: { 'x-auth': id }
      })
        .then(({ data }) => {

          const { profiles } = data;

          let selectedProfileIndex = window.localStorage.getItem('selectedProfileIndex');
          selectedProfileIndex = selectedProfileIndex ? parseInt(selectedProfileIndex, 10) : 0;
          selectedProfileIndex = profiles.length > selectedProfileIndex ? selectedProfileIndex : 0;

          this.setState({
            connected: true,
            selectedProfileIndex,
            id: data.authToken,
            me: data.me,
            profiles: data.profiles,
            plan: data.plan,
            active: data.active,
            soundNotificationOn: data.soundNotificationOn,
            agent: data.agent,
            ready: true,
            agentNotReady: data.agent && data.profiles.length === 0,
          });

          window.localStorage.setItem('id', data.authToken);

        })
        .catch((e) => {

          if (e.response) {
            const { status } = e.response;

            if (status === 401) {
              window.localStorage.removeItem('id');
              window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
            } else {
              this.setState({ connected: false, ready: false, error: true });
              message.error("Couldn't contact the server");
            }
          } else {
            this.setState({ connected: false, ready: false, error: true });
            message.error("Couldn't contact the server");
          }

        });

    } else {
      window.localStorage.removeItem('id');
      window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
    }
  }

  setPage = (newPage) => {
    this.setState({ page: newPage });
  }

  logout = () => {
    window.localStorage.removeItem('id');
    window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
  }

  setSelectedProfileIndex = (index) => {
    this.setState({ selectedProfileIndex: index });
  }

  setUnreadCount = (count) => {
    this.setState({ unreadCount: count });
  }

  getPageComponent = () => {

    const { id, me, plan, selectedProfileIndex, profiles } = this.state;

    switch (this.state.page) {
      case "profiles": {
        return <Profiles id={id} me={me} plan={plan} selectedProfileIndex={selectedProfileIndex} setSelectedProfileIndex={this.setSelectedProfileIndex} />;
      }
      case "chats": {
        return <Chats
          id={id} me={me} plan={plan}
          selectedProfileIndex={selectedProfileIndex}
          profileId={profiles[selectedProfileIndex]?._id}
          setSelectedProfileIndex={this.setSelectedProfileIndex}
          setUnreadCount={this.setUnreadCount}
          setPage={this.setPage}
        />;
      }
      case "contacts": {
        return <Contacts
          id={id} me={me} plan={plan}
          selectedProfileIndex={selectedProfileIndex}
          profileId={profiles[selectedProfileIndex]?._id}
          setSelectedProfileIndex={this.setSelectedProfileIndex}
          setPage={this.setPage}
        />;
      }
      case "groups": {
        return <Groups
          id={id} me={me} plan={plan}
          selectedProfileIndex={selectedProfileIndex}
          profileId={profiles[selectedProfileIndex]?._id}
          setSelectedProfileIndex={this.setSelectedProfileIndex}
          setPage={this.setPage}
        />;
      }
      case "agents": {
        return <Agents id={id} me={me} plan={plan} setPage={this.setPage} />;
      }
      case "billing": {
        return <Billing id={id} me={me} plan={plan} />;
      }
      case "account": {
        return <Account id={id} me={me} plan={plan} />;
      }
      case "logout": {
        return this.logout();
      }
      default: {
        return <Chats id={id} me={me} plan={plan} />;
      }
    }
  }

  setSidebarState = (newState) => {
    if (newState !== this.state.sidebarHidden) {
      this.setState({ sidebarHidden: newState });
    }
  }

  render() {
    const { page, connected, me, ready, error, sidebarHidden, unreadCount, agentNotReady } = this.state;

    return (
      <Router>
        <div className="bg-indigo-50 _min-h-screen" style={{ minWidth: '1099px' }}>
          {
            error && <p className='p-4 bg-white rounded-md mt-2 font-fira font-medium text-gray-7'>An unexpected error occured, reload the page or <a className="text-indigo-600" href="/login">Log in</a>!</p>
          }
          {
            agentNotReady && <p className='mx-2 p-4 bg-white rounded-md mt-2 font-fira font-medium text-gray-7'>Your agent account is almost ready, ask your administrator to give you access to at least one profile to start!</p>
          }
          {(ready && !agentNotReady) && <React.Fragment>
            {/* HEADER */}
            <Header setPage={this.setPage} unreadCount={unreadCount} me={me} />
            <div className="flex mt-32 px-5">
              <Sidebar page={page} setPage={this.setPage} setSidebarState={this.setSidebarState} />
              {connected && <div className={`w-full ${sidebarHidden ? '' : 'ml-72'}`}>
                {this.getPageComponent()}
              </div>}
            </div>
          </React.Fragment>}
        </div>
      </Router>
    )
  }
}

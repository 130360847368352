import React, { Component } from 'react';
import {
  Link,
} from "react-router-dom";

import Axios from '../api/axios';

import CONSTANTS from '../constants';

export default class Profiles extends Component {

  state = {
    ready: false,
    status: false,
    plan: "free",
    showErrorModal: false,
    showSuccessModal: false,
    showInfoModal: false,
    showConfirmModal: false,
    showProfilesModal: false,
    selectedProfiles: [],
    errorMessage: '',
    successMessage: '',
    infoMessage: '',
    confirmMessage: '',
    updating: false,
    confirmCallback: {},
    confirmCallbackData: {},
    subscriptionIsOk: false,
    agent: false,
    error: false,
    profiles: [],
    profilesCount: 0,
    name: '',
    type: '',
    apiKey: '',
    apiSecret: '',
    spaceUrl: '',
    phoneNumber: '',
    authKeyText: '',
    authSecretText: '',
    messagingServiceSid: '',
    showTest: false,
    ownPhoneNumber: '',
    code: '',
    codeSent: false,
    profileTestId: '',
    showMessageBirdExtraStep: false,
    flowJson: '',
  };

  componentDidMount() {
    this.loadProfiles();
  }

  loadProfiles = (selectLast = false) => {
    this.setState({ updating: true });
    Axios({
      url: '/profiles/all',
      method: 'get',
      headers: { 'x-auth': this.props.id }
    })
      .then(({ data }) => {

        this.setState({ updating: false });

        this.setState({
          ready: true,
          plan: data.plan,
          profiles: data.profiles,
          status: data.status,
          agent: data.agent,
          profilesCount: data.profilesCount,
          error: false,
        }, () => {
          if (selectLast) {
            this.props.setSelectedProfileIndex(this.state.profiles.length - 1);
          } else {
            this.setState({
              name: this.state.profiles[this.props.selectedProfileIndex].name,
              type: this.state.profiles[this.props.selectedProfileIndex].type,
              apiKey: this.state.profiles[this.props.selectedProfileIndex].apiKey ?? '',
              apiSecret: this.state.profiles[this.props.selectedProfileIndex].apiSecret ?? '',
              spaceUrl: this.state.profiles[this.props.selectedProfileIndex].spaceUrl ?? '',
              phoneNumber: this.state.profiles[this.props.selectedProfileIndex].phoneNumber ?? '',
              messagingServiceSid: this.state.profiles[this.props.selectedProfileIndex].messagingServiceSid ?? '',
            });
          }
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false, error: true });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  showError = (msg, delay = 10) => {
    this.setState({
      showErrorModal: true,
      errorMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showErrorModal: false,
          errorMessage: '',
        })
      }, delay * 1000);
    })
  }

  showSuccess = (msg, delay = 10) => {
    this.setState({
      showSuccessModal: true,
      successMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showSuccessModal: false,
          successMessage: '',
        })
      }, delay * 1000);
    })
  }

  showInfo = (msg, delay = 10) => {
    this.setState({
      showInfoModal: true,
      infoMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showInfoModal: false,
          infoMessage: '',
        })
      }, delay * 1000);
    })
  }

  showConfirm = (msg, callback, data) => {
    this.setState({
      showConfirmModal: true,
      confirmMessage: msg,
      confirmCallback: callback,
      confirmCallbackData: data,
    })
  }

  createNewProfile = () => {
    const { profilesCount, profiles, status } = this.state;

    if (!status) {
      return this.showError('Your subscription is inactive, update your plan to continue using the product!', 10);
    }

    if (profilesCount <= profiles.length) {
      return this.showError('You can\'t create more profiles, update your plan to do so!', 10);
    }

    this.setState({ updating: true });

    Axios({
      url: '/profiles/create',
      method: 'post',
      headers: { 'x-auth': this.props.id }
    })
      .then((_res) => {
        this.setState({ updating: false });
        this.loadProfiles(true);
      })
      .catch(({ response }) => {

        this.setState({ updating: false, error: true });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });

  }

  deleteProfile = () => {
    const { profiles } = this.state;

    if (profiles.length === 1) {
      return this.showError('Can\'t perform this action. You must have at least one profile!', 10);
    }

    this.setState({ updating: true });

    Axios({
      url: '/profiles/delete',
      method: 'post',
      data: {
        id: profiles.filter((_p, i) => i === this.props.selectedProfileIndex)[0]?._id,
      },
      headers: { 'x-auth': this.props.id }
    })
      .then((_res) => {

        this.setState({ updating: false });

        this.loadProfiles(true);
      })
      .catch(({ response }) => {

        this.setState({ updating: false, error: true });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  getAuthKeyText = () => {
    const { type } = this.state;
    switch (type) {
      case 'twilio': {
        return "Twilio Account SID*";
      }
      case 'messagebird': {
        return "Messagebird Access Key*";
      }
      case 'signalwire': {
        return "SignalWire Project ID*";
      }
      default: {
        return "Twilio Account SID*";
      }
    }
  }

  getAuthSecretText = () => {
    const { type } = this.state;
    switch (type) {
      case 'twilio': {
        return "Twilio Auth Token*";
      }
      case 'messagebird': {
        return "";
      }
      case 'signalwire': {
        return "SignalWire Token*";
      }
      default: {
        return "Twilio Auth Token*";
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedProfileIndex !== prevProps.selectedProfileIndex && this.state.profiles.length) {
      this.setState({
        name: this.state.profiles[this.props.selectedProfileIndex].name,
        type: this.state.profiles[this.props.selectedProfileIndex].type,
        apiKey: this.state.profiles[this.props.selectedProfileIndex].apiKey ?? '',
        apiSecret: this.state.profiles[this.props.selectedProfileIndex].apiSecret ?? '',
        spaceUrl: this.state.profiles[this.props.selectedProfileIndex].spaceUrl ?? '',
        phoneNumber: this.state.profiles[this.props.selectedProfileIndex].phoneNumber ?? '',
        messagingServiceSid: this.state.profiles[this.props.selectedProfileIndex].messagingServiceSid ?? '',
      });
    }
  }

  downloadFlow = () => {
    const { flowJson } = this.state;

    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(flowJson));
    element.setAttribute('download', 'KatarioFlow.json');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  displayTest = () => {

    const profile = this.state;

    if (!profile.name || !profile.type || !profile.apiKey || (!profile.phoneNumber && !profile.messagingServiceSid)) {
      return this.showError('Please fill all the required fields');
    }

    if (profile.type !== 'messagebird' && !profile.apiSecret) {
      return this.showError('Please fill all the required fields');
    }

    if (profile.type === 'twilio' && (!profile.phoneNumber && !profile.messagingServiceSid)) {
      return this.showError('Please fill all the required fields');
    }

    if (profile.type === 'signalWire' && (!profile.phoneNumber && !profile.messagingServiceSid)) {
      return this.showError('Please fill all the required fields');
    }

    if (profile.type === 'signalWire' && !profile.spaceUrl) {
      return this.showError('Please fill all the required fields');
    }

    if (profile.type === 'messagebird' && !profile.phoneNumber) {
      return this.showError('Please fill all the required fields');
    }

    if (profile.phoneNumber && !/^\+[1-9]\d{6,14}$/.test(profile.phoneNumber)) {
      return this.showError('Please enter a valid phone number');
    }

    if (profile.type === 'twilio' && !profile.apiKey.startsWith('AC')) {
      return this.showError('Twilio account SID starts with AC');
    }

    this.setState({ codeSent: false, ownPhoneNumber: '', profileTestId: '', showTest: true });
  }

  onlyNameChanged = () => {

    if (!this.state.profiles[this.props.selectedProfileIndex].apiKey) {
      return false;
    }

    if (this.state.type !== this.state.profiles[this.props.selectedProfileIndex].type) {
      return false;
    }

    if (this.state.phoneNumber !== this.state.profiles[this.props.selectedProfileIndex].phoneNumber) {
      return false;
    }

    if (this.state.apiKey !== this.state.profiles[this.props.selectedProfileIndex].apiKey) {
      return false;
    }

    if (this.state.apiSecret !== this.state.profiles[this.props.selectedProfileIndex].apiSecret && this.state.profiles[this.props.selectedProfileIndex].apiSecret) {
      return false;
    }

    if (this.state.spaceUrl !== this.state.profiles[this.props.selectedProfileIndex].spaceUrl && this.state.profiles[this.props.selectedProfileIndex].spaceUrl) {
      return false;
    }

    if (this.state.messagingServiceSid !== this.state.profiles[this.props.selectedProfileIndex].messagingServiceSid && this.state.profiles[this.props.selectedProfileIndex].messagingServiceSid) {
      return false;
    }

    return true;
  }

  updateName = () => {
    const { name, status, profiles } = this.state;

    if (!name) {
      return;
    }

    if (!status) {
      return this.showError('Your subscription is inactive, update your plan to continue using the product!', 10);
    }

    this.setState({ updating: true });

    Axios({
      url: '/profiles/update',
      method: 'post',
      headers: { 'x-auth': this.props.id },
      data: {
        name,
        profileId: profiles.filter((_p, i) => i === this.props.selectedProfileIndex)[0]?._id,
      },
    })
      .then((_res) => {
        this.setState({ updating: false });
        this.loadProfiles();
      })
      .catch(({ response }) => {

        this.setState({ updating: false, error: true });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  testProfile = () => {
    const profile = this.state;

    if (!profile.status) {
      return this.showError('Your subscription is inactive, update your plan to continue using the product!', 10);
    }

    if (!profile.name || !profile.type || !profile.apiKey || (!profile.phoneNumber && !profile.messagingServiceSid)) {
      return this.showError('Please fill all the required fields');
    }

    if (profile.type !== 'messagebird' && !profile.apiSecret) {
      return this.showError('Please fill all the required fields');
    }

    if (profile.type === 'twilio' && (!profile.phoneNumber && !profile.messagingServiceSid)) {
      return this.showError('Please fill all the required fields');
    }

    if (profile.type === 'signalWire' && (!profile.phoneNumber && !profile.messagingServiceSid)) {
      return this.showError('Please fill all the required fields');
    }

    if (profile.type === 'signalWire' && !profile.spaceUrl) {
      return this.showError('Please fill all the required fields');
    }

    if (profile.type === 'messagebird' && !profile.phoneNumber) {
      return this.showError('Please fill all the required fields');
    }

    if (profile.phoneNumber && !/^\+[1-9]\d{6,14}$/.test(profile.phoneNumber)) {
      return this.showError('Please enter a valid phone number');
    }

    if (profile.type === 'twilio' && !profile.apiKey.startsWith('AC')) {
      return this.showError('Twilio account SID starts with AC');
    }

    if (!profile.ownPhoneNumber || !/^\+[1-9]\d{6,14}$/.test(profile.ownPhoneNumber)) {
      return this.showError('Please enter a valid phone number');
    }

    this.setState({ updating: true });

    Axios({
      url: '/profiles/test',
      method: 'post',
      data: { ...profile, profileId: profile.profiles.filter((_p, i) => i === this.props.selectedProfileIndex)[0]?._id, privatePhoneNumber: profile.ownPhoneNumber },
      headers: { 'x-auth': this.props.id }
    })
      .then((res) => {


        this.setState({ updating: false });

        this.setState({
          profileTestId: res.data.profileTestId, codeSent: true
        });

        this.showSuccess('Confirmation code sent to your personal phone number.');
      })
      .catch(({ response }) => {

        this.setState({ updating: false, error: true });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  confirmProfile = () => {
    const profile = this.state;

    if (!profile.profileTestId || !profile.code) {
      return this.showError('Please fill all the required fields');
    }

    if (!profile.status) {
      return this.showError('Your subscription is inactive, update your plan to continue using the product!', 10);
    }

    this.setState({ updating: true });

    Axios({
      url: '/profiles/confirm',
      method: 'post',
      data: { profileTestId: profile.profileTestId, profileId: profile.profiles.filter((_p, i) => i === this.props.selectedProfileIndex)[0]?._id, code: profile.code },
      headers: { 'x-auth': this.props.id }
    })
      .then((res) => {

        this.setState({
          codeSent: false, profileTestId: '', showTest: false, code: '', ownPhoneNumber: '', flowJson: res.data.flowJson ?? '', updating: false,
        }, () => {
          this.loadProfiles();
          if (profile.type === 'messagebird') {
            this.setState({ showMessageBirdExtraStep: true });
          }
        });
        this.showSuccess('Profile confirmed, you can now send and receive messages wih it');
      })
      .catch(({ response }) => {

        this.setState({ updating: false, error: true });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  render() {

    const {
      ready, showSuccessModal, showInfoModal, showErrorModal,
      showConfirmModal, successMessage, infoMessage,
      errorMessage, confirmMessage, updating, confirmCallback,
      agent, confirmCallbackData,
      error, flowJson,
      profiles, profilesCount, type, name, apiKey, apiSecret, spaceUrl, phoneNumber, messagingServiceSid,
      showTest, ownPhoneNumber, code, codeSent, profileTestId, showMessageBirdExtraStep,
    } = this.state;

    return (
      <div>
        {(ready || error) || <div className="mx-auto max-w-billing">
          <h1 className="text-2xl font-nunito font-bold">Loading...</h1>
        </div>}

        {
          error && !ready && <p className='p-4 bg-white rounded-md mt-2 font-fira font-medium text-gray-7 mx-auto max-w-billing'>An unexpected error occured, reload the page!</p>
        }

        {
          ready && <div className="mx-auto max-w-billing">
            <h1 className="text-2xl font-nunito font-bold">Profiles (Phone numbers)</h1>
            <p className="text-base font-fira text-gray-400">Manage your profiles</p>

            {
              agent && <p className='p-4 bg-white rounded-md mt-2 font-fira font-medium text-gray-7'>You don't have access to this page.</p>
            }

            {
              !agent && <div>
                <div className="bg-white p-6 pt-4 mt-2 rounded-md">
                  <p className="text-gray-600 font-fira font-semibold text-lg">Remaining profiles: {profilesCount - profiles.length} of {profilesCount}  &nbsp;
                    <span className="text-xs text-gray-500">Need more? Update your plan <Link className="text-indigo-500" onClick={() => this.props.setPage('billing')} to="/billing?update=true" > here </Link></span>
                  </p>
                  <p className="text-gray-600 font-fira font-semibold text-lg mt-4">CURRENT PROFILE</p>
                  <div className="mt-3">
                    <select value={this.props.selectedProfileIndex} onChange={(e) => this.props.setSelectedProfileIndex(parseInt(e.target.value, 10))} className="block w-full h-11 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600">
                      {
                        profiles.map((p, i) => (
                          <option key={i} value={i}>{p.name}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="mt-4 text-right space-x-5">
                    <button
                      type="button"
                      disabled={updating}
                      onClick={this.createNewProfile}
                      className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                      <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${updating ? '' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Create new profile
                    </button>
                    <button
                      type="button"
                      disabled={updating}
                      onClick={() => {
                        this.showConfirm(
                          `Are you sure to delete this profile (${profiles.filter((_p, i) => i === this.props.selectedProfileIndex)[0]?.name})? Any Contact, Group, Message, associated with this profile will also be deleted. This can't be undone.`,
                          this.deleteProfile,
                          {}
                        );
                      }}
                      className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                      <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${updating ? '' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Delete current profile
                    </button>
                  </div>
                </div>
                <div className="bg-white p-6 pt-4 mt-4 rounded-md mb-6">
                  <p className="text-gray-800 font-fira font-semibold text-xl">Profile settings
                    &nbsp;
                    <span className="text-xs text-gray-600">Don't know how to fill these fields? Check this&nbsp;
                      <a className="text-indigo-500" href="https://docs.katario.com/quick-start" target="_blank" rel="noreferrer" >quickstart</a> or&nbsp;
                      <a className="text-indigo-500" href="https://katario.com/contact-us" target="_blank" rel="noreferrer">contact us</a></span>
                  </p>
                  <form>
                    <div className="mt-4 text-lg font-fira font-medium">
                      <label className="text-gray-700" htmlFor="profileName">Profile Name*, <span className="text-gray-600 text-base">give a name to this profile</span></label>
                      <input value={name} onChange={(e) => this.setState({ name: e.target.value })} id="profileName" type="text" placeholder='' className="block w-full h-11 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600" />
                    </div>
                    <div className="mt-4 text-lg font-fira font-medium">
                      <label className="text-gray-700" htmlFor="provider">Provider*, <span className="text-gray-600 text-base">choose where you got the phone number</span></label>
                      <select value={type} onChange={(e) => this.setState({ type: e.target.value })} id="provider" type="text" placeholder='' className="block w-full h-11 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600">
                        <option value="twilio">TWILIO</option>
                        <option value="messagebird">MESSAGEBIRD</option>
                        <option value="signalwire">SIGNALWIRE</option>
                      </select>
                    </div>
                    <div className="mt-4 text-lg font-fira font-medium">
                      <label className="text-gray-700" htmlFor="apiKey">{this.getAuthKeyText()}, <span className="text-gray-600 text-base">you can find it <a className="text-indigo-600" href="https://docs.katario.com/guides/other/get-the-key" target="_blank" rel="noreferrer">here</a></span></label>
                      <input value={apiKey} onChange={(e) => this.setState({ apiKey: e.target.value })} placeholder={`${type === "twilio" ? "ACXXXXXXXXXXXX" : ""}`} id="apiKey" type="text" className="block w-full h-11 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600" />
                    </div>
                    {type !== "messagebird" && <div className="mt-4 text-lg font-fira font-medium">
                      <label className="text-gray-700" htmlFor="apiSecret">{this.getAuthSecretText()} <span className="text-gray-600 text-base">you can find it <a className="text-indigo-600" href="https://docs.katario.com/guides/other/token" target="_blank" rel="noreferrer">here</a></span></label>
                      <input value={apiSecret} onChange={(e) => this.setState({ apiSecret: e.target.value })} placeholder="*****************" id="apiSecret" type="text" className="block w-full h-11 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600" />
                    </div>}
                    {type === "signalwire" && <div className="mt-4 text-lg font-fira font-medium">
                      <label className="text-gray-700" htmlFor="spaceUrl"> Space URL*, <span className="text-gray-600 text-base">You can find it here <a className="text-indigo-600" href="https://docs.katario.com/guides/other/signalwire-space-url" target="_blank" rel="noreferrer">here</a></span></label>
                      <input value={spaceUrl} onChange={(e) => this.setState({ spaceUrl: e.target.value })} placeholder="EX: myworkspace.signlawire.com" id="spaceUrl" type="url" className="block w-full h-11 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600" />
                    </div>}
                    <div className="mt-4 text-lg font-fira font-medium">
                      <label className="text-gray-700" htmlFor="phoneNumber">{type[0]?.toUpperCase() + type.slice(1)} Phone Number <span className="text-sm">(required if Messaging Service SID is not provided)</span>*, <span className="text-gray-600 text-base">Get one <a className="text-indigo-600" href="https://docs.katario.com/guides/other/twilio-messaging-service-sid" target="_blank" rel="noreferrer">here</a></span></label>
                      <input value={phoneNumber} onChange={(e) => this.setState({ phoneNumber: e.target.value })} placeholder="EX: +14151231234" id="phoneNumber" type="tel" className="block w-full h-11 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600" />
                    </div>
                    {type !== "messagebird" && <div className="mt-4 text-lg font-fira font-medium">
                      <label className="text-gray-700" htmlFor="messagingService">{type[0]?.toUpperCase() + type.slice(1)} Messaging Service SID <span className="text-sm">(required if Phone Number is not provided)</span>*</label>
                      <input value={messagingServiceSid} onChange={(e) => this.setState({ messagingServiceSid: e.target.value })} id="messagingService" placeholder="MGXXXXXXXXXXXXXXXXXX" type="text" className="block w-full h-11 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600" />
                    </div>}
                    <div className="mt-3 text-right">
                      <button
                        type="button"
                        disabled={updating}
                        onClick={() => {
                          if (this.onlyNameChanged()) {
                            this.updateName();
                          } else {
                            this.displayTest();
                          }
                        }}
                        className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                        <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${updating ? '' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {!this.onlyNameChanged() ? 'Confirm and save' : 'Save'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            }

          </div>
        }

        {/* ERROR MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showErrorModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showErrorModal: false, errorMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-red-500">{errorMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showErrorModal: false, errorMessage: '' });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* SUCCESS MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showSuccessModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showSuccessModal: false, successMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-green-500">{successMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showSuccessModal: false, successMessage: '' });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* INFO MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showInfoModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showInfoModal: false, infoMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-blue-500">{infoMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showInfoModal: false, infoMessage: '' });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* CONFIRM MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showConfirmModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
          }}
        >
          <div className="modal bg-white max-w-lg shadow-sm pb-2 mx-auto relative rounded" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded border-b">
              <span className="text-lg font-fira font-medium text-indigo-500">{confirmMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
            <p className="text-right space-x-4 pt-2 pr-4">
              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Cancel
              </button>

              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  confirmCallback(confirmCallbackData);
                  setTimeout(() => {
                    this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
                  }, 300);
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Confirm
              </button>
            </p>
          </div>
        </div>

        {/* PROFILES CONFIRMATION MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-0 ${showTest ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showTest: false });
          }}
        >
          <div className="modal bg-white max-w-lg shadow-sm pb-2 mx-auto relative rounded" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded border-b">
              <span className="text-lg font-fira font-medium text-gray-600">To ensure that the data you have entered is correct, we will use it to send you a confirmation code on your own phone number (not the one you got on {type[0]?.toUpperCase() + type.slice(1)}).</span>
              <span
                onClick={() => {
                  this.setState({ showTest: false });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
            <div className="border-b px-4 py-4">

              <div className="mt-4 text-lg font-fira font-medium">
                <label className="text-gray-700" htmlFor="phoneNumber">Your personal phone number <span className="text-sm">(not saved, just used for profile confirmation)</span>*</label>
                <input value={ownPhoneNumber} onChange={(e) => this.setState({ ownPhoneNumber: e.target.value })} placeholder="EX: +14151231234" id="phoneNumber" type="tel" className="block w-full h-11 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600" />
              </div>

              <div className="mt-4 text-lg font-fira font-medium">
                <label className="text-gray-700" htmlFor="phoneNumber">Confirmation code</label>
                <input disabled={!codeSent || !profileTestId} value={code} onChange={(e) => this.setState({ code: e.target.value })} placeholder="EX: KAT782154" id="phoneNumber" type="text" className="block w-full h-11 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600" />
              </div>

              <div className="mt-4">
                <p className="text-base font-fira text-gray-600">
                  Not receiving the confirmation code? &nbsp;
                  <a href="https://katario.com/contact-us" target="_blank" rel="noreferrer">
                    <span className="text-xs text-indigo-500">Contact us</span>
                  </a>
                </p>
              </div>

            </div>

            <p className="text-right space-x-4 pt-2 pr-4">
              <button
                type="button"
                disabled={updating}
                onClick={this.testProfile}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-green-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${updating ? '' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Send confirmation code
              </button>

              <button
                type="button"
                disabled={!codeSent || !profileTestId || updating}
                onClick={this.confirmProfile}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${updating ? '' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Confirm and save
              </button>
            </p>
          </div>
        </div>

        {/* MESSAGEBIRD EXTRASTEP MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showMessageBirdExtraStep ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showMessageBirdExtraStep: false });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 pb-2 rounded">
              <span className="text-lg font-fira font-medium text-gray-500">Last step (only for MessageBird)!</span>
              <span
                onClick={() => {
                  this.setState({ showMessageBirdExtraStep: false });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
            <div className="p-6 pt-1">
              <div className="">
                <p className="text-gray-700 font-fira text-base mb-3">Download the file below, import it on MessageBird Flow Builder and publish it.</p>
                <button
                  type="button"
                  disabled={!flowJson}
                  onClick={() => this.downloadFlow()}
                  className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                  Download Flow
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

import React, { Component } from 'react';
import {
  Link,
} from "react-router-dom";

import Axios from '../api/axios';

import CONSTANTS from '../constants';

export default class Agents extends Component {
  state = {
    ready: false,
    status: false,
    plan: "free",
    showErrorModal: false,
    showSuccessModal: false,
    showInfoModal: false,
    showConfirmModal: false,
    showProfilesModal: false,
    selectedAgentId: "",
    selectedProfiles: [],
    errorMessage: "",
    successMessage: "",
    infoMessage: "",
    confirmMessage: "",
    updating: false,
    confirmCallback: {},
    confirmCallbackData: {},
    agentEmail: '',
    agents: [],
    subscriptionIsOk: false,
    selectedValues: {},
    agent: false,
    agentsCount: 0,
    error: false,
    profiles: [],
  };

  componentDidMount() {
    this.loadAgent();
  }


  loadAgent = () => {
    this.setState({ updating: true });
    Axios({
      url: '/auth/agents',
      method: 'get',
      headers: { 'x-auth': this.props.id }
    })
      .then(({ data }) => {

        this.setState({ updating: false });

        this.setState({
          agents: data.agents,
          ready: true,
          plan: data.plan,
          status: data.status,
          agent: data.agent,
          agentsCount: data.agentsCount,
          error: false,
          profiles: data.profiles,
        });


      })
      .catch(({ response }) => {

        this.setState({ updating: false, error: true });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  showError = (msg, delay = 10) => {
    this.setState({
      showErrorModal: true,
      errorMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showErrorModal: false,
          errorMessage: "",
        })
      }, delay * 1000);
    })
  }

  showSuccess = (msg, delay = 10) => {
    this.setState({
      showSuccessModal: true,
      successMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showSuccessModal: false,
          successMessage: "",
        })
      }, delay * 1000);
    })
  }

  showInfo = (msg, delay = 10) => {
    this.setState({
      showInfoModal: true,
      infoMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showInfoModal: false,
          infoMessage: "",
        })
      }, delay * 1000);
    })
  }

  showConfirm = (msg, callback, data) => {
    this.setState({
      showConfirmModal: true,
      confirmMessage: msg,
      confirmCallback: callback,
      confirmCallbackData: data,
    })
  }

  sendInvite = () => {
    const { agentEmail, status, plan } = this.state;

    if (!status) {
      return this.showError('Your subscription is inactive, update your plan to continue using the product!', 10);
    }

    if (!agentEmail) {
      return this.showError('Please enter the agent email address', 10);
    }

    if (plan !== 'business') {
      return;
    }

    this.setState({ updating: true });

    Axios({
      url: '/auth/invite',
      method: 'post',
      data: { email: agentEmail },
      headers: { 'x-auth': this.props.id }
    })
      .then((_res) => {

        this.setState({ updating: false });
        this.showSuccess(`Invite sent to ${agentEmail}`, 5);

        this.loadAgent();

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  deleteAgent = (agentId) => {

    this.setState({ updating: true });

    Axios({
      url: '/auth/agent',
      method: 'delete',
      data: { agentId },
      headers: { 'x-auth': this.props.id }
    })
      .then((_res) => {

        this.setState({ updating: false });

        const { agents } = this.state;

        this.setState({
          agents: agents.filter((a) => a._id !== agentId),
        })
        this.showSuccess("Agent account deleted!");
      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  deleteInvite = (inviteId) => {

    this.setState({ updating: true });

    Axios({
      url: '/auth/invite',
      method: 'delete',
      data: { inviteId },
      headers: { 'x-auth': this.props.id }
    })
      .then((_res) => {

        this.setState({ updating: false });

        const { agents } = this.state;

        this.setState({
          agents: agents.filter((a) => a._id !== inviteId),
        })
        this.showSuccess("Invite deleted!");
      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });

  }

  updateProfilesAccess = () => {
    const { selectedProfiles, selectedAgentId } = this.state;

    if (!selectedAgentId || this.state.plan !== 'business') {
      return;
    }

    this.setState({ updating: true });

    Axios({
      url: '/auth/agent',
      method: 'patch',
      data: { agentId: selectedAgentId, profilesIds: selectedProfiles },
      headers: { 'x-auth': this.props.id }
    })
      .then((_res) => {
        this.setState({ updating: false, showProfilesModal: false, selectedAgentId: "", selectedProfiles: [] });

        this.loadAgent();
      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  getAgentName = () => {
    return this.state.agents.filter((a) => a._id === this.state.selectedAgentId)[0]?.fullname;
  }

  render() {
    const {
      ready, showSuccessModal, showInfoModal, showErrorModal,
      showConfirmModal, successMessage, infoMessage,
      errorMessage, confirmMessage, updating, confirmCallback,
      plan, agent, agentEmail, agentsCount, agents, confirmCallbackData,
      error, showProfilesModal, selectedProfiles,
      profiles,
    } = this.state;
    return (
      <div>
        {(ready || error) || <div className="mx-auto max-w-billing">
          <h1 className="text-2xl font-nunito font-bold">Loading...</h1>
        </div>}
        {
          error && !ready && <p className='p-4 bg-white rounded-md mt-2 font-fira font-medium text-gray-7 mx-auto max-w-billing'>An unexpected error occured, reload the page!</p>
        }
        {
          ready && <div className="mx-auto max-w-billing">
            <h1 className="text-2xl font-nunito font-bold">Agents</h1>
            <p className="text-base font-fira text-gray-400">Manage registered agents in your workspace</p>

            {
              plan !== "business" && !agent && <p className='p-4 bg-white rounded-md mt-2 font-fira font-medium text-gray-700'>
                You need to upgrade your plan to BUSINESS to have agents &nbsp;
                <Link onClick={() => this.props.setPage('billing')} to="/billing?update=true" >
                  <span className="text-xs text-indigo-500">Update it here</span>
                </Link>
              </p>
            }

            {
              agent && <p className='p-4 bg-white rounded-md mt-2 font-fira font-medium text-gray-7'>You don't have access to this page.</p>
            }

            {
              (plan === "business" && !agent) && <div>

                <div className="bg-white p-6 pt-4 mt-2 rounded-md">
                  <p className="text-gray-600 font-fira font-semibold text-lg">Remaining agent seats: {agentsCount - agents.filter((a) => !a.invite).length} of {agentsCount}  &nbsp;
                    <span className="text-xs text-gray-500">Need more? Update your plan <Link className="text-indigo-500" onClick={() => this.props.setPage('billing')} to="/billing?update=true"> here </Link></span>
                  </p>

                  <div className="mt-3">
                    <label htmlFor="email" className="text-sm font-fira font-medium">AGENT ADDRESS</label>
                    <input
                      autoComplete="username"
                      value={agentEmail} onChange={(e) => this.setState({ agentEmail: e.target.value })}
                      required
                      placeholder="johndoe@example.com"
                      type="email"
                      name="email"
                      id="email"
                      className="block w-full h-12 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600"
                    />
                  </div>

                  <div className="mt-4 text-right">
                    <button
                      type="button"
                      disabled={updating}
                      onClick={this.sendInvite}
                      className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                      <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${updating ? '' : 'hidden'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Send invite
                    </button>
                  </div>

                </div>

                <div className="bg-white p-6 pt-4 mt-2 rounded-md">
                  <p className="text-gray-600 font-fira font-semibold text-lg">Your agents</p>

                  {
                    agents.length === 0 && <p className="text-gray-600 font-fira mt-2">You have no agent yet</p>
                  }

                  <div>

                    {
                      agents.map((ag, i) => {
                        if (ag.invite) {
                          return (
                            <div key={i} className="border rounded-md shadow mt-2 p-4 flex justify-between invite space-x-5">
                              <p className="font-fira">Invite sent to <span className="font-medium">{ag.email}</span></p>
                              <p className={`font-fira text-base font-medium ${ag.accepted ? 'text-green-600' : 'text-indigo-600'} align-middle`} style={{ alignSelf: "center", marginLeft: "auto" }}>
                                {ag.accepted ? 'ACCEPTED' : 'PENDING'}
                              </p>
                              <p className="invite-delete opacity-0 pr-8 pl-10 text-right" style={{ alignSelf: "center" }}>
                                <button disabled={updating} onClick={(e) => {
                                  e.stopPropagation();
                                  this.deleteInvite(ag._id);
                                }} className="material-icons-outlined hover:bg-indigo-100">delete</button>
                              </p>
                            </div>
                          )
                        } else {
                          return (
                            <div key={i} className="border rounded-md shadow mt-2 p-4 flex agent">
                              <p>
                                <span className="material-icons-outlined w-12 h-12 text-indigo-700">account_circle</span>
                              </p>
                              <div>
                                <p className="font-fira font-medium">{ag.fullname}</p>
                                <p className="font-fira text-sm font-medium text-gray-600">{ag.email}</p>
                              </div>
                              <div className="space-x-10 pr-8 opacity-0 agent-actions" style={{ alignSelf: "center", marginLeft: "auto" }}>
                                <button disabled={updating} onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({
                                    showProfilesModal: true,
                                    selectedAgentId: ag._id,
                                    selectedProfiles: ag.profiles.map((p) => p._id),
                                  });
                                }} className="material-icons-outlined hover:bg-indigo-100">settings</button>
                                <button disabled={updating} onClick={(e) => {
                                  e.stopPropagation();
                                  this.showConfirm('Are you sure to remove this agent? This agent account will be deleted. This can\'t be undone.', this.deleteAgent, ag._id);
                                }} className="material-icons-outlined hover:bg-indigo-100">delete</button>
                              </div>
                            </div>
                          )
                        }
                      })
                    }

                  </div>
                </div>


              </div>
            }
          </div>
        }

        {/* ERROR MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showErrorModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showErrorModal: false, errorMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-red-500">{errorMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showErrorModal: false, errorMessage: "" });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* SUCCESS MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showSuccessModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showSuccessModal: false, successMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-green-500">{successMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showSuccessModal: false, successMessage: "" });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* INFO MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showInfoModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showInfoModal: false, infoMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-indigo-500">{infoMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showInfoModal: false, infoMessage: "" });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* CONFIRM MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showConfirmModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
          }}
        >
          <div className="modal bg-white max-w-lg shadow-sm pb-2 mx-auto relative rounded" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded border-b">
              <span className="text-lg font-fira font-medium text-indigo-500">{confirmMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showConfirmModal: false, confirmMessage: "", confirmCallback: {}, confirmCallbackData: {} });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
            <p className="text-right space-x-4 pt-2 pr-4">
              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  this.setState({ showConfirmModal: false, confirmMessage: "", confirmCallback: {}, confirmCallbackData: {} });
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Cancel
              </button>

              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  confirmCallback(confirmCallbackData);
                  setTimeout(() => {
                    this.setState({ showConfirmModal: false, confirmMessage: "", confirmCallback: {}, confirmCallbackData: {} });
                  }, 300);
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Confirm
              </button>
            </p>
          </div>
        </div>

        {/* PROFILES ACCESS MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 ${showProfilesModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showProfilesModal: false, selectedAgentId: "", selectedProfiles: [] });
          }}
        >
          <div className="modal bg-white max-w-lg shadow-sm pb-2 mx-auto relative rounded" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded border-b">
              <span className="text-lg font-fira font-medium text-gray-600">Profiles access for <span className="text-indigo-500 font-medium">{this.getAgentName()}</span></span>
              <span
                onClick={() => {
                  this.setState({ showProfilesModal: false, selectedAgentId: "", selectedProfiles: [] });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
            <div className="border-b px-4 py-4">
              {
                profiles.map((p, i) => (
                  <div className="font-fira" key={i}>
                    <label className="space-x-3">
                      <input
                        checked={selectedProfiles.includes(p._id)}
                        onChange={(e) => {
                          if (e.target.checked && !selectedProfiles.includes(p._id)) {
                            this.setState({ selectedProfiles: [...selectedProfiles, p._id] })
                          } else if (!e.target.checked && selectedProfiles.includes(p._id)) {
                            this.setState({ selectedProfiles: selectedProfiles.filter((sp) => sp !== p._id) })
                          }
                        }}
                        className="h-5 w-5"
                        type="checkbox"
                        name="checkbox"
                      />
                      <span className="text-base" style={{ verticalAlign: "top" }}>{p.name}</span>
                    </label>
                  </div>
                ))
              }
            </div>
            <p className="text-right space-x-4 pt-2 pr-4">
              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  this.updateProfilesAccess();
                  setTimeout(() => {
                    this.setState({ showProfilesModal: false, selectedAgentId: "", selectedProfiles: [] });
                  }, 300);
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Save
              </button>
            </p>
          </div>
        </div>

      </div >
    )
  }
}

import React, { Component } from 'react';
import { Upload } from 'antd';
import {
  Link,
} from "react-router-dom";

import Axios from '../api/axios';

import CONSTANTS from '../constants';

const GREY_IMGAE_DATA_URI = "data:image/svg+xml,%3Csvg width='72' height='64' viewBox='0 0 72 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='72' height='64' fill='%23C4C4C4'/%3E%3C/svg%3E%0A";

export default class Groups extends Component {
  state = {
    ready: false,
    status: false,
    plan: "free",
    showErrorModal: false,
    showSuccessModal: false,
    showInfoModal: false,
    showConfirmModal: false,
    showProfilesModal: false,
    selectedProfiles: [],
    errorMessage: '',
    successMessage: '',
    infoMessage: '',
    confirmMessage: '',
    updating: false,
    confirmCallback: {},
    confirmCallbackData: {},
    agent: false,
    error: false,
    profiles: [],
    contacts: [],
    bulkActionActive: false,
    showProfileOpen: false,
    showEdit: false,
    editedContactIndex: null,
    displayedGroupIndex: null,
    tags: [],
    inputVisible: false,
    inputValue: '',
    editing: {
      groupImageUrl: '',
      name: '',
      phoneNumber: '',
      email: '',
      company: '',
      title: '',
      phoneError: false,
    },
    selected: [],
    filterLabel: '',
    filterText: '',
    importContacts: [],
    selectedForImport: [],
    showUpload: false,
    showImport: false,
    selectAll: false,
    groups: [],
    showAdd: false,
    editedGroupIndex: null,
    filterLabelAlt: '',
    filterTextAlt: '',
    allContacts: [],
    selectedForGroup: [],
    selectAllAlt: false,
  }

  componentDidMount() {
    this.loadGroups();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedProfileIndex !== prevProps.selectedProfileIndex && this.state.profiles.length) {
      this.loadGroups();
    }
  }

  handleClose = (removedTag) => {
    const tags = this.state.tags.filter(tag => tag !== removedTag);
    // console.log(tags);
    this.setState({ tags });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue.toLocaleLowerCase().replace(/,/, '')];
    }
    // console.log(tags);
    this.setState({
      tags,
      inputVisible: false,
      inputValue: '',
    });
  };

  handleEditInputChange = (e) => {
    this.setState({ editInputValue: e.target.value });
  };

  handleEditInputConfirm = () => {
    this.setState(({ tags }) => {
      const newTags = [...tags];

      return {
        tags: newTags,
      };
    });
  };

  saveInputRef = (input) => {
    this.input = input;
  };

  saveEditInputRef = (input) => {
    this.editInput = input;
  };

  updateGroup = () => {

    const {
      groups,
      editedGroupIndex,
      tags,
      editing,
      status,
    } = this.state;

    if (!status) {
      return this.showError('Your subscription is inactive, update your plan to continue using the product!', 10);
    }

    this.setState({ updating: true });

    Axios({
      url: `/groups/updateInfo`,
      method: 'post',
      headers: { 'x-auth': this.props.id },
      data: { ...editing, labels: tags, groupId: groups[editedGroupIndex]._id, profileId: this.props.profileId },
    })
      .then((res) => {

        groups[editedGroupIndex] = res.data.group;
        this.setState({
          groups,
          showEdit: false,
          updating: false,
        });
      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  createGroup = () => {
    const {
      groups,
      status,
    } = this.state;

    if (!status) {
      return this.showError('Your subscription is inactive, update your plan to continue using the product!', 10);
    }

    this.setState({ updating: true });

    Axios({
      url: `/groups/create`,
      method: 'post',
      headers: { 'x-auth': this.props.id },
      data: { profileId: this.props.profileId },
    })
      .then((res) => {

        const lastIndex = groups.length;

        groups[lastIndex] = res.data.group;

        this.setState({
          groups,
          showEdit: true,
          editing: { ...res.data.group },
          tags: res.data.group.labels,
          editedGroupIndex: lastIndex,
          updating: false,
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  deleteGroup = (groupId) => {
    const {
      groups,
    } = this.state;

    this.setState({ updating: true });

    Axios({
      url: `/groups/delete`,
      method: 'post',
      headers: { 'x-auth': this.props.id },
      data: { profileId: this.props.profileId, groupId },
    })
      .then((_res) => {

        this.setState({
          groups: groups.filter((c) => c._id !== groupId),
          showEdit: false,
          updating: false,
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  toggleSelected = (index) => {
    const { selected } = this.state;
    this.setState({
      selected: selected.includes(index) ? selected.filter((v) => v !== index) : [...selected, index],
    });
  }

  toggleSelectedForAdding = (index) => {
    const { selectedForGroup } = this.state;
    this.setState({
      selectedForGroup: selectedForGroup.includes(index) ? selectedForGroup.filter((v) => v !== index) : [...selectedForGroup, index],
    });
  }

  bulkDelete = () => {
    const {
      groups,
      selected,
    } = this.state;

    if (selected.length <= 0) return;

    this.setState({ updating: true });

    Axios({
      url: `/groups/deleteMany`,
      method: 'post',
      headers: { 'x-auth': this.props.id },
      data: { profileId: this.props.profileId, groupIds: groups.filter((_c, i) => selected.includes(i)).map((c) => c._id) },
    })
      .then((_res) => {

        this.setState({
          groups: groups.filter((c, i) => !selected.includes(i)),
          selected: [],
          updating: false,
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });

  }

  getAllMatchesLength = () => {
    const { groups, filterLabel, filterText } = this.state;

    return groups.map((group, index) => {
      if (((filterLabel
        && group.labels
        && group.labels.includes(filterLabel))
        || (filterLabel === ""))
        && ((filterText
          && ((group.name && group.name.toLocaleLowerCase().includes(filterText))))
          || (filterText === ""))) {
        return index;
      } else {
        return null;
      }
    }).filter((v) => v != null).length;
  }

  getAllMatchesLengthAlt = () => {
    const { allContacts, filterLabelAlt, filterTextAlt } = this.state;

    return allContacts.map((contact, index) => {
      if (((filterLabelAlt
        && contact.labels
        && contact.labels.includes(filterLabelAlt))
        || (filterLabelAlt === ""))
        && ((filterTextAlt
          && ((contact.name && contact.name.toLocaleLowerCase().includes(filterTextAlt))
            || (contact.phoneNumber && contact.phoneNumber.startsWith(filterTextAlt))
            || (contact.email
              && contact.email.startsWith(filterTextAlt)) || (contact.company
                && contact.company.toLocaleLowerCase().includes(filterTextAlt)) || (contact.title
                  && contact.title.toLocaleLowerCase().includes(filterTextAlt))))
          || (filterTextAlt === ""))) {
        return index;
      } else {
        return null;
      }
    }).filter((v) => v != null).length;
  }

  selectAll = () => {

    const { selected, groups, selectAll, filterLabel, filterText } = this.state;

    const indexesToSelectOrDeselect = groups.map((group, index) => {
      if (((filterLabel
        && group.labels
        && group.labels.includes(filterLabel))
        || (filterLabel === ""))
        && ((filterText
          && ((group.name && group.name.toLocaleLowerCase().includes(filterText))))
          || (filterText === ""))) {
        return index;
      } else {
        return null;
      }
    }).filter((v) => v != null);

    //// console.log(indexesToSelectOrDeselect, selectAll);

    if (selectAll) {
      this.setState({ selected: selected.filter((v) => !indexesToSelectOrDeselect.includes(v)), selectAll: !selectAll })
    } else {
      this.setState({ selected: indexesToSelectOrDeselect, selectAll: !selectAll });
    }

  }

  selectAllAlt = () => {

    const { selectedForGroup, allContacts, selectAllAlt, filterLabelAlt, filterTextAlt } = this.state;

    const indexesToSelectOrDeselect = allContacts.map((contact, index) => {
      if (((filterLabelAlt
        && contact.labels
        && contact.labels.includes(filterLabelAlt))
        || (filterLabelAlt === ""))
        && ((filterTextAlt
          && ((contact.name && contact.name.toLocaleLowerCase().includes(filterTextAlt))
            || (contact.phoneNumber && contact.phoneNumber.startsWith(filterTextAlt))
            || (contact.email
              && contact.email.startsWith(filterTextAlt)) || (contact.company
                && contact.company.toLocaleLowerCase().includes(filterTextAlt)) || (contact.title
                  && contact.title.toLocaleLowerCase().includes(filterTextAlt))))
          || (filterTextAlt === ""))) {
        return index;
      } else {
        return null;
      }
    }).filter((v) => v != null);

    //// console.log(indexesToSelectOrDeselect, selectAllAlt);

    if (selectAllAlt) {
      this.setState({ selectedForGroup: selectedForGroup.filter((v) => !indexesToSelectOrDeselect.includes(v)), selectAllAlt: !selectAllAlt })
    } else {
      this.setState({ selectedForGroup: indexesToSelectOrDeselect, selectAllAlt: !selectAllAlt });
    }

  }

  loadAllContacts = (index) => {
    const { groups, status } = this.state;

    if (!status) {
      return this.showError('Your subscription is inactive, update your plan to continue using the product!', 10);
    }

    this.setState({ updating: true });

    Axios({
      url: `/contacts/all/${this.props.profileId}`,
      method: 'get',
      headers: { 'x-auth': this.props.id }
    })
      .then((res) => {

        this.setState({ updating: false });

        const existingContactsIds = groups[index].participants.map((p) => p._id);

        this.setState({
          allContacts: res.data.contacts.filter((c) => c.phoneNumber),
          showAdd: true,
          editedGroupIndex: index,
          selectedForGroup: res.data.contacts.map((c, i) => existingContactsIds.includes(c._id) ? i : null).filter((c) => c != null),
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  updateGroupParticipants = () => {
    const {
      groups,
      selectedForGroup,
      editedGroupIndex,
      allContacts,
      status,
    } = this.state;

    if (!status) {
      return this.showError('Your subscription is inactive, update your plan to continue using the product!', 10);
    }

    this.setState({ updating: true });

    Axios({
      url: `/groups/updateParticipants`,
      method: 'post',
      headers: { 'x-auth': this.props.id },
      data: { profileId: this.props.profileId, groupId: groups[editedGroupIndex]._id, contactIds: allContacts.filter((_c, i) => selectedForGroup.includes(i)).map((c) => c._id) },
    })
      .then((res) => {
        this.setState({ updating: false });

        groups[editedGroupIndex] = res.data.group;

        this.setState({
          groups,
          selectedForGroup: [],
          showAdd: false,
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  loadGroups = () => {
    this.setState({ updating: true });
    Axios({
      url: `/groups/all/${this.props.profileId}`,
      method: 'get',
      headers: { 'x-auth': this.props.id },
    })
      .then(({ data }) => {

        this.setState({ updating: false });

        this.setState({
          ready: true,
          profiles: data.profiles,
          status: data.status,
          error: false,
          groups: data.groups,
        });

      })
      .catch(({ response }) => {

        this.setState({ updating: false, error: true });

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.LOGIN_PAGE_URL}`;
          } else if (status === 429) {
            this.showError("Please slow down! Wait 2 minutes and try again");
          } else if (status === 400) {
            this.showError(response.data.errors[0].msg);
          } else if (status === 500) {
            this.showError(response.data.message);
          } else {
            this.showError("An unexpected error occured!");
          }
        } else {
          this.showError("Network error!");
        }

      });
  }

  showError = (msg, delay = 10) => {
    this.setState({
      showErrorModal: true,
      errorMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showErrorModal: false,
          errorMessage: '',
        })
      }, delay * 1000);
    })
  }

  showSuccess = (msg, delay = 10) => {
    this.setState({
      showSuccessModal: true,
      successMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showSuccessModal: false,
          successMessage: '',
        })
      }, delay * 1000);
    })
  }

  showInfo = (msg, delay = 10) => {
    this.setState({
      showInfoModal: true,
      infoMessage: msg,
    }, () => {
      setTimeout(() => {
        this.setState({
          showInfoModal: false,
          infoMessage: '',
        })
      }, delay * 1000);
    })
  }

  showConfirm = (msg, callback, data) => {
    this.setState({
      showConfirmModal: true,
      confirmMessage: msg,
      confirmCallback: callback,
      confirmCallbackData: data,
    })
  }

  removeTag = (tag) => {
    const { tags } = this.state;

    this.setState({ tags: tags.filter((t) => t !== tag) });
  }

  render() {
    const {
      ready, showSuccessModal, showInfoModal, showErrorModal,
      showConfirmModal, successMessage, infoMessage,
      errorMessage, confirmMessage, updating, confirmCallback, confirmCallbackData,
      error, profiles, groups, bulkActionActive, showProfileOpen,
      displayedGroupIndex, showEdit, tags,
      inputValue, editing, selected, filterLabel, filterText, showUpload, selectAll,
      editedGroupIndex, filterLabelAlt, filterTextAlt, showAdd, allContacts, selectedForGroup, selectAllAlt,
    } = this.state;

    return (
      <div>
        {(ready || error) || <div className="mx-auto max-w-billing">
          <h1 className="text-2xl font-nunito font-bold">Loading...</h1>
        </div>}
        {
          error && !ready && <p className='p-4 bg-white rounded-md mt-2 font-fira font-medium text-gray-7 mx-auto max-w-billing'>An unexpected error occured, reload the page!</p>
        }
        {
          ready && <div className="mx-auto max-w-billing">
            <h1 className="text-2xl font-nunito font-bold">Groups</h1>
            <p className="text-base font-fira text-gray-400">Manage your groups (broadcast channels)</p>

            {/* PROFILE SWITCH */}
            <div className="bg-white p-6 pt-4 pb-5 mt-2 rounded-md">
              <div className="mt-0 flex space-x-5">
                <p className="font-fira font-medium text-gray-800" style={{ alignSelf: "center" }}>CURRENT PROFILE</p>
                <p className="flex-1">
                  <select value={this.props.selectedProfileIndex} onChange={(e) => this.props.setSelectedProfileIndex(parseInt(e.target.value, 10))} className="w-full h-11 px-6 border border-indigo-400 rounded-md mt-2 bg-gray-100 text-lg font-fira text-gray-600">
                    {
                      profiles.map((p, i) => (
                        <option key={i} value={i}>{p.name}</option>
                      ))
                    }
                  </select>
                </p>
              </div>
            </div>

            <div className={`bg-white p-6 pt-4 pb-5 mt-2 rounded-md ${updating ? 'opacity-20' : ''}`}>
              <div className="flex justify-between font-fira text-gray-700">
                <div className="flex justify-start space-x-3">
                  <div className="relative">
                    <input value={filterText} onChange={(e) => this.setState({ filterText: e.target.value.toLocaleLowerCase() })} type="search" className="bg-purple-50 bg-opacity-50 focus:shadow hover:shadow focus:bg-opacity-20 outline-none rounded border-0 p-3 pl-10" placeholder="Search" />
                    <div className="absolute top-3 pl-2">
                      <span className="material-icons-outlined text-gray-400">search</span>
                    </div>
                  </div>


                  <div className="group inline-block">
                    <button
                      className="outline-none focus:outline-none border border-gray-400 rounded p-3 px-4  bg-white flex items-center min-w-32"
                    >
                      <span className="pr-1 font-semibold flex-1"><span className="material-icons-outlined align-middle">filter_alt</span> &nbsp;{filterLabel !== '' ? filterLabel : 'All groups'}</span>
                      <span>
                        <svg
                          className="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                          />
                        </svg>
                      </span>
                    </button>
                    <ul
                      className="bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32"
                    >
                      <li key={20000} onClick={() => this.setState({ filterLabel: '' })} className="rounded-sm px-3 py-1 hover:bg-gray-100">All groups</li>
                      {
                        Array.from(new Set(groups.map((c) => c.labels ? c.labels.join(',') : null).filter((v) => v).join(',').split(','))).filter((v) => v).sort().map((l, i) => {
                          return <li key={i} onClick={() => this.setState({ filterLabel: l })} className="rounded-sm px-3 py-1 hover:bg-gray-100 cursor-pointer">{l}</li>
                        })
                      }
                    </ul>
                  </div>
                </div>
                <div className="flex justify-end space-x-3">
                  <button onClick={this.createGroup} className="text-center font-medium bg-indigo-600 text-white p-2 px-4 rounded-md hover:bg-opacity-90">
                    <span className="material-icons-outlined align-middle">group_add</span> &nbsp;Add New
                  </button>
                </div>
              </div>

              <p className="p-6 text-gray-500">
                <input className="w-4 h-4" checked={selectAll} onChange={(e) => this.selectAll()} type="checkbox" /> &nbsp;
                <span className="align-text-bottom font-fira">Select All Matches ({this.getAllMatchesLength()})</span>
              </p>

              <table className="min-w-full divide-y divide-gray-200 shadow">
                <thead className=" font-fira">
                  <tr>
                    <th
                      scope="col"
                      className="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer w-10"
                    >
                      <div x-data="{ dropdownOpen: true }" className="relative">
                        <button
                          className="relative z-10 block bg-gray-100 rounded p-2 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
                          onClick={() => this.setState({ bulkActionActive: !bulkActionActive })}
                          onBlur={() => setTimeout(() => this.setState({ bulkActionActive: !bulkActionActive }), 300)}
                        >
                          <svg className="h-6 w-6 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                          </svg>
                        </button>

                        <div x-show="dropdownOpen" className={`${bulkActionActive ? '' : 'hidden'} absolute left-0 mt-2 w-36 bg-white rounded-md overflow-hidden shadow-xl z-20`}>
                          <button onClick={() => {
                            this.showConfirm("Are you sure to delete these groups?", this.bulkDelete, {});
                          }} className="block px-4 py-2 text-sm text-gray-800 border-b hover:bg-gray-200 w-full">Delete selected</button>
                        </div>
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Members
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Tags
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {groups.map((group, index) => (
                    (((filterLabel
                      && group.labels
                      && group.labels.includes(filterLabel))
                      || (filterLabel === ""))
                      && ((filterText
                        && ((group.name && group.name.toLocaleLowerCase().includes(filterText))))
                        || (filterText === "")))
                      ? (<tr key={group._id} className="contact" onClick={null}>
                        <td className="pl-4 py-4 whitespace-nowrap w-10">
                          <input type="checkbox" className="w-4 h-4" checked={selected.includes(index)} onChange={() => this.toggleSelected(index)} />
                        </td>
                        <td
                          className="px-3 py-4 whitespace-nowrap cursor-pointer"
                          onClick={() => this.setState({ showProfileOpen: true, displayedGroupIndex: index })}
                        >
                          <div className="flex items-center font-fira">
                            <div className="flex-shrink-0 h-10 w-10">
                              {group.groupImageUrl
                                ? <img loading="lazy" className="h-10 w-10 rounded-full bg-cover" src={group.groupImageUrl} onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }} alt="pp" />
                                : <span className="material-icons-outlined text-7xl text-indigo-900" style={{ fontSize: '2.5em' }}>groups</span>
                              }
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{group.name?.slice(0, 25)}</div>
                              <div className="text-sm text-gray-500">{group.participants?.length} participants</div>
                            </div>
                          </div>
                        </td>
                        <td
                          onClick={() => this.loadAllContacts(index)}
                          className="px-3 py-4 whitespace-nowrap font-fira cursor-pointer" >
                          <div className="text-sm text-gray-900">
                            {group.participants.length > 0 && ((group.participants[0] && group.participants[0].profilePictureUrl)
                              ? <img style={{ verticalAlign: 'sub' }} className="inline h-gicon w-gicon rounded-full bg-cover" src={group.participants[0].profilePictureUrl} onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI; }} alt="pp" />
                              : <i className='fa fa-user-circle text-red-400 rounded-full' style={{ fontSize: '2.3em' }}></i>)
                            }
                            {group.participants.length > 1 && ((group.participants[1] && group.participants[1].profilePictureUrl)
                              ? <img style={{ verticalAlign: 'sub' }} className="inline h-gicon w-gicon rounded-full -ml-1 z-10 bg-cover" src={group.participants[1].profilePictureUrl} onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI; }} alt="pp" />
                              : <i className='fa fa-user-circle text-red-400 -ml-1 z-10 rounded-full' style={{ fontSize: '2.3em' }}></i>)
                            }
                            <span className="material-icons-outlined text-indigo-900 inline-block" style={{ marginLeft: '-5px', verticalAlign: 'sub', fontSize: '2.6em' }}>add_circle_outline</span>
                          </div>
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap w-20 font-fira cursor-pointer" onClick={() => this.setState({ showProfileOpen: true, displayedGroupIndex: index })}>
                          {(group.labels && group.labels[0])
                            ? <span>
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indigo-800">
                                {group.labels[0]?.slice(0, 10)}
                              </span>
                              {group.labels.length > 1 ? '+' : ''}
                            </span>
                            :
                            <span>-</span>
                          }
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap cursor-pointer"
                        >
                          <div className="flex justify-between text-gray-500 opacity-0 contact-actions">

                            <Link
                              to={`/chats?group=${group._id}`}
                              onClick={() => {
                                this.props.setPage("chats");
                              }}
                            >
                              <span className={`material-icons-outlined hover:bg-indigo-100 ${group.participants.length > 0 ? '' : 'opacity-0'}`}>send</span>
                            </Link>

                            <button disabled={updating} onClick={(e) => {
                              e.stopPropagation();
                              this.setState({
                                showEdit: true,
                                editedGroupIndex: index,
                                editing: { ...groups[index] },
                                tags: groups[index].labels
                              })
                            }} className="material-icons-outlined hover:bg-indigo-100">edit</button>
                            <button disabled={updating} onClick={(e) => {
                              e.stopPropagation();
                              this.showConfirm(`Do you want to delete this group (${group.name})?`, this.deleteGroup, group._id);
                            }} className="material-icons-outlined hover:bg-indigo-100">delete</button>
                          </div>
                        </td>
                      </tr>)
                      : <React.Fragment key={group._id} />
                  ))}
                </tbody>
              </table>
            </div>

          </div>
        }

        {/* ERROR MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showErrorModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showErrorModal: false, errorMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-red-500">{errorMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showErrorModal: false, errorMessage: '' });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* SUCCESS MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showSuccessModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showSuccessModal: false, successMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-green-500">{successMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showSuccessModal: false, successMessage: '' });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* INFO MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showInfoModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showInfoModal: false, infoMessage: '' });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded">
              <span className="text-lg font-fira font-medium text-blue-500">{infoMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showInfoModal: false, infoMessage: '' });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
          </div>
        </div>

        {/* CONFIRM MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-50 ${showConfirmModal ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
          }}
        >
          <div className="modal bg-white max-w-lg shadow-sm pb-2 mx-auto relative rounded" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-between p-6 rounded border-b">
              <span className="text-lg font-fira font-medium text-indigo-500">{confirmMessage}</span>
              <span
                onClick={() => {
                  this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
            <p className="text-right space-x-4 pt-2 pr-4">
              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Cancel
              </button>

              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  confirmCallback(confirmCallbackData);
                  setTimeout(() => {
                    this.setState({ showConfirmModal: false, confirmMessage: '', confirmCallback: {}, confirmCallbackData: {} });
                  }, 300);
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Confirm
              </button>
            </p>
          </div>
        </div>

        {/* GROUP DISPLAY MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-10 ${showProfileOpen ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showProfileOpen: false, displayedGroupIndex: null });
          }}
        >
          <div className="modal bg-white max-w-lg shadow-sm py-2 mx-auto relative rounded" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>

            <div className="bg-white pb-2 sm:pb-1">
              <div className="font-fira">
                {Number.isInteger(displayedGroupIndex) && groups[displayedGroupIndex] && <div className="">
                  <div className="bg-indigo-900 m-4 p-7 rounded-xl pb-16">
                    <p className="text-center">
                      {groups[displayedGroupIndex].groupImageUrl ? <img className="h-20 w-20 rounded-lg inline-block text-center bg-cover" onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }} src={groups[displayedGroupIndex].groupImageUrl} alt="pp" />
                        : <span className="material-icons-outlined text-7xl text-white">groups</span>
                      }
                    </p>
                    <p className="text-center text-xl font-medium text-white mt-3 font-roboto">{groups[displayedGroupIndex].name}</p>
                    <p className="text-center text-lg text-white mt-2 font-roboto text-opacity-60">
                      {groups[displayedGroupIndex].participants.length} Participants
                      <br />
                      {groups[displayedGroupIndex].participants.map((p, i) => <span className='inline-block mr-1' key={i}>{p.name}, </span>)}
                    </p>
                  </div>
                  <div className="bg-white m-8 -mt-12 p-5 rounded-xl pb-30 shadow-md">
                    <p className="text-center text-indigo-800 text-lg font-medium text-blue mt-2 font-roboto">{groups[displayedGroupIndex].phoneNumber}</p>
                    <p className="text-center mt-3">
                      {groups[displayedGroupIndex].labels
                        && groups[displayedGroupIndex].labels.map((label, index) => {
                          return <span
                            key={index}
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indgio-800"
                          >
                            {label}
                          </span>
                        })}
                    </p>
                  </div>
                </div>
                }
              </div>
            </div>

            <p className="p-6 rounded text-center border-b"></p>
            <p className="flex justify-end space-x-4 pt-2 pr-4">
              <Link to={`/chats?group=${groups[displayedGroupIndex]?._id}`} onClick={() => {
                this.setState({ showProfileOpen: false });
                this.props.setPage("chats");
              }}>
                <button
                  type="button"
                  disabled={updating}
                  onClick={() => {
                    this.setState({ showConfirmModal: false });
                  }}
                  className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200 ${groups[displayedGroupIndex]?.participants.length > 0 ? '' : 'hidden'}`}>
                  <span className="material-icons-outlined">send</span> &nbsp;
                  Message
                </button>
              </Link>


              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  this.setState({ showProfileOpen: false, displayedGroupIndex: null });
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Close
              </button>
            </p>
          </div>
        </div>

        {/* EDIT GROUP MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-10 overflow-y-auto ${showEdit ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({
              showEdit: false,
              editing: {
                groupImageUrl: '',
                name: '',
                phoneNumber: '',
                email: '',
                company: '',
                title: '',
                phoneError: false,
              },
            });
          }}
        >
          <div className="modal bg-white max-w-lg shadow-sm pb-2 mx-auto relative rounded " style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>


            <div className="px-6 font-fira mb-3">

              <p className="pt-8 rounded text-center">
                {editing.groupImageUrl ? <img className="h-24 w-24 rounded-lg inline-block text-center bg-cover" onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }} src={editing.groupImageUrl} alt='broken' /> : ''}
                {!editing.groupImageUrl ? <span className="material-icons-outlined text-7xl text-indigo-900">account_circle</span> : ''}
              </p>

              <p>
                <label className="block text-gray-500 text-base font-medium mt-2 mb-1" htmlFor="profilePictureUrl">
                  Group Picture URL &nbsp;
                  <a href="https://docs.katario.com/help/how-to-get-an-image-url" target="_blank" rel="noreferrer">
                    <span className="text-xs text-indigo-500">How to get an image URL?</span>
                  </a>
                </label>
                <input
                  className="block w-full h-12 px-6 border border-indigo-400 rounded-md mt-2 text-base font-fira font-medium text-gray-600"
                  id="profilePictureUrl"
                  type="url"
                  placeholder="https://example.com/image.jpg"
                  value={editing.groupImageUrl}
                  onChange={(e) => {
                    const { editing } = this.state;
                    editing.groupImageUrl = e.target.value;
                    this.setState({ editing });
                  }}
                  autoFocus={false}
                />
              </p>
            </div>

            <div className="px-6 mb-3 font-fira">
              <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
                Group Name *
              </label>
              <input
                className="block w-full h-12 px-6 border border-indigo-400 rounded-md mt-2 text-base font-fira font-medium text-gray-600"
                id="name"
                type="text"
                placeholder="John doe"
                value={editing.name}
                onChange={(e) => {
                  const { editing } = this.state;
                  editing.name = e.target.value;
                  this.setState({ editing });
                }}
                autoFocus={true}
              />
            </div>

            <div className="px-6 mb-3 font-fira">
              <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="position">
                Tags (use a comma to add a new tag)
              </label>
              <input
                className="block w-full h-12 px-6 border border-indigo-400 rounded-md mt-2 text-base font-fira font-medium text-gray-600"
                id="position"
                type="text"
                placeholder="Ex: customer"
                value={inputValue}
                onChange={(e) => {
                  const { value } = e.target;

                  if (value === '' || !value.includes(',')) {
                    return this.setState({ inputValue: value });
                  }

                  const newTag = value.split(',')[0];

                  if (!newTag || newTag === '') {
                    return this.setState({ inputValue: value });
                  }

                  if (tags.includes(newTag)) {
                    return this.setState({ inputValue: '' });
                  }

                  this.setState({ tags: [...tags, newTag], inputValue: '' });
                }}
              />
              <div className="mt-2">
                {
                  tags.map((t) => (
                    <div className="pl-2 bg-indigo-200 inline-flex items-center rounded-md mt-1 ml-1">
                      <span className="py-1">{t}</span> &nbsp;
                      <span onClick={() => this.removeTag(t)} className="material-icons-outlined py-1 text-white bg-indigo-400 rounded-r-md cursor-pointer">close</span>
                    </div>
                  ))
                }
              </div>

            </div>

            <p className="bg-white rounded border-b p-1"></p>
            <p className="text-right space-x-4 pt-2 pr-4">
              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  this.setState({
                    showEdit: false,
                    editing: {
                      profilePictureUrl: '',
                      name: '',
                      phoneNumber: '',
                      email: '',
                      company: '',
                      position: '',
                      phoneError: false,
                    },
                  });
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Cancel
              </button>

              <button
                type="button"
                disabled={updating}
                onClick={this.updateGroup}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Save
              </button>
            </p>
          </div>
        </div>

        {/* UPLOAD MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-10 ${showUpload ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({ showUpload: false });
          }}
        >
          <div className="modal bg-white max-w-lg rounded shadow-sm pb-2 mx-auto relative" style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="bg-white flex justify-end rounded px-6 pt-6">
              <span
                onClick={() => {
                  this.setState({ showUpload: false });
                }}
                className="material-icons material-icons-outlined text-bluegray-500 cursor-pointer text-2xl">
                close
              </span>
            </p>
            <p className="font-fira px-6 text-xl font-medium">
              Import your Contacts
            </p>
            <p className="px-6 mt-3 font-fira">
              <span className="material-icons-outlined align-middle  text-yellow-500">
                warning
              </span>
              &nbsp; For the import to work, you must follow <a href="/d" target="_blank" className="text-indigo-600">THIS STRUCTURE</a>
            </p>
            <div className="text-center my-4">
              <Upload {...this.uprops}>
                <button className="text-center font-medium border border-gray-400 p-2 px-4 rounded-md hover:bg-gray-100">
                  <span className="material-icons-outlined align-middle">file_upload</span> &nbsp;Upload
                </button>
              </Upload>
            </div>
          </div>
        </div>

        {/* UPDATE GROUP PARTICIPANT MODAL */}
        <div className={`w-full h-screen bg-gray-400 bg-opacity-80 fixed top-0 left-0 z-10 overflow-y-auto ${showAdd ? '' : 'hidden'}`}
          onClick={() => {
            this.setState({
              showAdd: false,
            });
          }}
        >
          <div className="modal bg-white max-w-3xl shadow-sm pb-2 mx-auto relative rounded " style={{ top: "10%" }} onClick={(e) => { e.stopPropagation(); }}>
            <p className="text-lg font-fira p-4">Group participants of <span className="text-indigo-900"> {groups[editedGroupIndex]?.name}</span>  ({selectedForGroup.length})</p>

            <p className="p-6 text-gray-500 font-fira">
              <input className="align-text-top" checked={selectAllAlt} onChange={(e) => this.selectAllAlt()} type="checkbox" />&nbsp;
              <span className="align-text-bottom">Select All Matches ({this.getAllMatchesLengthAlt()})</span>
            </p>


            <div className="flex justify-start space-x-3 p-4">
              <div className="relative">
                <input value={filterTextAlt} onChange={(e) => this.setState({ filterTextAlt: e.target.value.toLocaleLowerCase() })} type="search" className="bg-purple-50 bg-opacity-50 focus:shadow hover:shadow focus:bg-opacity-20 outline-none rounded border-0 p-3 pl-10" placeholder="Search" />
                <div className="absolute top-3 pl-2">
                  <span className="material-icons-outlined text-gray-400">search</span>
                </div>
              </div>


              <div className="group inline-block">
                <button
                  className="outline-none focus:outline-none border border-gray-400 rounded p-3 px-4  bg-white flex items-center min-w-32"
                >
                  <span className="pr-1 font-semibold flex-1"><span className="material-icons-outlined align-middle">filter_alt</span> &nbsp;{filterLabelAlt !== '' ? filterLabelAlt : 'All contacts'}</span>
                  <span>
                    <svg
                      className="fill-current h-4 w-4 transform group-hover:-rotate-180 transition duration-150 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                      />
                    </svg>
                  </span>
                </button>
                <ul
                  className="bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32"
                >
                  <li key={20000} onClick={() => this.setState({ filterLabelAlt: '' })} className="rounded-sm px-3 py-1 hover:bg-gray-100">All contacts</li>
                  {
                    Array.from(new Set(allContacts.map((c) => c.labels ? c.labels.join(',') : null).filter((v) => v).join(',').split(','))).filter((v) => v).sort().map((l, i) => {
                      return <li key={i} onClick={() => this.setState({ filterLabelAlt: l })} className="rounded-sm px-3 py-1 hover:bg-gray-100 cursor-pointer">{l}</li>
                    })
                  }
                </ul>
              </div>
            </div>



            <div className="mt-2 px-4 max-h-screen overflow-y-auto">
              <table className="min-w-full divide-y divide-gray-200 shadow">
                <thead className=" font-fira">
                  <tr>
                    <th
                      scope="col"
                      className="pl-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer w-10"
                    >
                      <div x-data="{ dropdownOpen: true }" className="relative">
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="pl-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Phone
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Position
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Tags
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {allContacts.map((contact, index) => (
                    (((filterLabelAlt
                      && contact.labels
                      && contact.labels.includes(filterLabelAlt))
                      || (filterLabelAlt === ""))
                      && ((filterTextAlt
                        && ((contact.name && contact.name.toLocaleLowerCase().includes(filterTextAlt))
                          || (contact.phoneNumber && contact.phoneNumber.startsWith(filterTextAlt))
                          || (contact.email
                            && contact.email.startsWith(filterTextAlt)) || (contact.company
                              && contact.company.toLocaleLowerCase().includes(filterTextAlt)) || (contact.title
                                && contact.title.toLocaleLowerCase().includes(filterTextAlt))))
                        || (filterTextAlt === "")))
                      ? (<tr key={index} className="contact" onClick={null}>
                        <td className="pl-4 py-4 whitespace-nowrap w-10">
                          <input type="checkbox" className="w-4 h-4" checked={selectedForGroup.includes(index)} onChange={() => { }} onClick={() => this.toggleSelectedForAdding(index)} />
                        </td>
                        <td
                          className="px-3 py-4 whitespace-nowrap cursor-pointer w-20"
                        >
                          <div className="flex items-center font-fira">
                            <div className="flex-shrink-0 h-10 w-10">
                              {contact.profilePictureUrl
                                ? <img loading="lazy" className="h-10 w-10 rounded-full bg-cover" src={contact.profilePictureUrl} onError={(e) => { e.target.src = GREY_IMGAE_DATA_URI }} alt="pp" />
                                : <span className="material-icons-outlined text-7xl text-indigo-900" style={{ fontSize: '2.5em' }}>account_circle</span>
                              }
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">{contact.name?.slice(0, 25)}</div>
                              <div className="text-sm text-gray-500">{contact.email?.slice(0, 25)}</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap w-20 font-fira">
                          <div className="text-sm text-indigo-900">{contact.phoneNumber}</div>
                        </td>
                        <td
                          className="px-3 py-4 whitespace-nowrap cursor-pointer"
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">{contact.title}</div>
                            <div className="text-sm text-gray-500">{contact.company ? `at ${contact.company}` : '-'}</div>
                          </td>
                        </td>
                        <td className="px-3 py-4 whitespace-nowrap w-20 font-fira cursor-pointer" onClick={() => this.setState({ showProfileOpen: true, displayedGroupIndex: index })}>
                          {(contact.labels && contact.labels[0])
                            ? <span>
                              <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-100 text-indigo-800">
                                {contact.labels[0]?.slice(0, 10)}
                              </span>
                              {contact.labels.length > 1 ? '+' : ''}
                            </span>
                            :
                            <span>-</span>
                          }
                        </td>
                      </tr>)
                      : <React.Fragment key={contact._id} />
                  ))}
                </tbody>
              </table>
            </div>

            <p className="bg-white rounded border-b p-1"></p>
            <p className="text-right space-x-4 pt-2 pr-4">
              <button
                type="button"
                disabled={updating}
                onClick={() => {
                  this.setState({
                    showAdd: false,
                    selectedForGroup: [],
                  });
                }}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-red-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Cancel
              </button>

              <button
                type="button"
                disabled={updating}
                onClick={this.updateGroupParticipants}
                className={`inline-flex items-center ${updating ? 'cursor-not-allowed' : ''} bg-indigo-500 px-6 py-3 text-white font-source rounded font-bold hover:opacity-80 duration-200`}>
                Add selected
              </button>
            </p>
          </div>
        </div>

      </div >
    )
  }
}

import React, { Component } from 'react';

import {
  Link,
} from "react-router-dom";

const mainLinks = [{
  path: "profiles",
  text: "Profiles",
  icon: "settings_cell",
},
{
  path: "chats",
  text: "Chats",
  icon: "sms",
},
{
  path: "contacts",
  text: "Contacts",
  icon: "contacts",
},
{
  path: "groups",
  text: "Groups",
  icon: "groups",
},
{
  path: "agents",
  text: "Agents",
  icon: "people_outline",
}]

const secondaryLinks = [{
  path: "billing",
  text: "Billing",
  icon: "credit_card",
},
{
  path: "account",
  text: "Account",
  icon: "settings",
},
{
  path: "logout",
  text: "Log out",
  icon: "power_settings_new",
}]



export default class Sidebar extends Component {

  state = {
    hidden: false,
  };

  componentDidMount() {
    this.setState({ hidden: this.props.page === 'chats' });
    this.props.setSidebarState(this.props.page === 'chats');
  }

  componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page) {
      this.setState({ hidden: this.props.page === 'chats' });
    }
    this.props.setSidebarState(this.state.hidden);
  }

  render() {
    const { hidden } = this.state;
    return (
      <React.Fragment>
        {
          hidden || (
            <div className="bg-white w-64 pb-8 pl-8 rounded-md fixed" style={{ alignSelf: "self-start" }}>
              <p
                onClick={() => {
                  this.setState({ hidden: true });
                }}
                className="cursor-pointer text-right mt-2 mr-2">
                <span className="material-icons-outlined text-xl">arrow_back</span>
              </p>
              <ul>
                {
                  mainLinks.map((l, i) => (
                    <li key={i}>
                      <Link to={l.path} onClick={() => this.props.setPage(l.path)}>
                        <p className={`mt-8 text-lg font-source font-semibold hover:text-indigo-600 ${this.props.page === l.path ? 'text-indigo-600  border-r-4 border-indigo-600' : 'text-gray-400'}`}>
                          <span className="material-icons-outlined align-middle">{l.icon}</span> &nbsp;
                          {l.text}
                        </p>
                      </Link>
                    </li>
                  ))
                }
              </ul>

              <ul className="mt-28">
                {
                  secondaryLinks.map((l, i) => (
                    <li key={i}>
                      <Link to={l.path} onClick={() => this.props.setPage(l.path)}>
                        <p className={`mt-8 text-lg font-source font-semibold hover:text-indigo-600 ${this.props.page === l.path ? 'text-indigo-600  border-r-4 border-indigo-600' : 'text-gray-400'}`}>
                          <span className="material-icons-outlined align-middle">{l.icon}</span> &nbsp;
                          {l.text}
                        </p>
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div>
          )
        }
        {
          hidden && (
            <div
              onClick={() => {
                this.setState({ hidden: false });
              }}
              className="bg-white px-3 py-2 rounded-md fixed cursor-pointer" style={{ alignSelf: "self-start" }}>
              <span className="material-icons-outlined text-xl">arrow_forward</span>
            </div>
          )
        }
      </React.Fragment>
    )
  }
}
